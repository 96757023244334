import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
 
const AddTeacher = () => {
  const navigate = useNavigate();
  const [newTeacher, setNewTeacher] = useState({
    teacher_id: "",
    name: "",
    qualification: "",
    mobile: "",
    email: "",
    hire_Date: "",
    subject_specialization: "",
    teacherCategory: "",
    teacherSubCategory: "",
    departmentId: ""
  });
 
  const [profileImage, setProfileImage] = useState(null); // New state for image
  const [profileImagePreview, setProfileImagePreview] = useState(""); // State for the image preview
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
 
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [departments, setDepartments] = useState([]);
 
  const [qualifications] = useState([
    "Diploma in Elementary Education (D.El.Ed)",
    "Bachelor of Elementary Education (B.El.Ed)",
    "Bachelor of Education (B.Ed)",
    "Master of Education (M.Ed)",
    "Teacher Eligibility Test (TET)",
    "Central Teacher Eligibility Test (CTET)",
    "State Eligibility Test (SET)",
    "National Eligibility Test (NET)",
    "Ph.D.",
    "Diploma in Education (D.Ed)",
    "Postgraduate Diploma in Education (PGDE)",
    "Master of Philosophy (M.Phil) in Education",
    "Certificate in Guidance (CIG)",
    "Special Education Certificate",
    "Bachelor of Physical Education (B.P.Ed)",
    "Master of Physical Education (M.P.Ed)"
  ]);
 
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };
 
  // Fetch teacher categories
  useEffect(() => {
    const fetchTeacherCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teachercategory`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`
          }
        }
        );
        setTeacherCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchTeacherCategories();
  }, []);
 
  // Fetch teacher subcategories based on selected category
  useEffect(() => {
    const fetchTeacherSubCategories = async () => {
      if (!newTeacher.teacherCategory) return; // Skip if no category is selected
      console.log(newTeacher.teacherCategory);
     
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teacher/sub/${newTeacher.teacherCategory}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setTeacherSubCategories(response.data.data);
        // Filter subcategories based on the selected category
        setFilteredSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher sub-categories:", error);
        setError("Failed to fetch teacher sub-categories");
      }
    };
    fetchTeacherSubCategories();
  }, [newTeacher.teacherCategory]); // Depend on teacherCategory
 
  // Update filtered subcategories when subcategories data changes
  useEffect(() => {
    if (newTeacher.teacherCategory) {
      setFilteredSubcategories(
        teacherSubCategories.filter(sub => sub.teacher_category_id === newTeacher.teacherCategory)
      );
    }
  }, [teacherSubCategories, newTeacher.teacherCategory]);
 
  // Fetch teacher categories
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department/getassigneddepartmentstosc`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchDepartments();
  }, []);
 
  const addTeacher = async (e) => {
    e.preventDefault();
    setLoading(true);
 
    const formData = new FormData();
    formData.append("teacher_id", newTeacher.teacher_id);
    formData.append("name", newTeacher.name);
    formData.append("qualification", newTeacher.qualification);
    formData.append("mobile", newTeacher.mobile);
    formData.append("email", newTeacher.email);
    formData.append("hire_Date", newTeacher.hire_Date);
    formData.append("subject_specialization", newTeacher.subject_specialization);
    formData.append("teacherCategory", newTeacher.teacherCategory);
    formData.append("teacherSubCategory", newTeacher.teacherSubCategory);
    formData.append("departmentId", newTeacher.departmentId);
 
 
    // Append profile image
    if (profileImage) {
      formData.append("profileImage", profileImage);
    }
 
    try {
      const token = getToken();
      if (!token) {
        setError("Access Denied. Please log in.");
        return;
      }
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/teacher`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      
      
      if(response.status===201){
        // Store success message in sessionStorage
        sessionStorage.setItem("AddTeacherSuccessMessage", "Teacher added successfully");
        setNewTeacher({
          teacher_id: "",
          name: "",
          qualification: "",
          mobile: "",
          email: "",
          hire_Date: "",
          subject_specialization: "",
          teacherCategory: "",
          teacherSubCategory: "",
          departmentId: ""
        });
        // Redirect to Labnames list
        navigate("/teachers");
      }
    } catch (err) {
      console.error("Error adding teacher:", err);
      setError(err.response?.data?.message || "Failed to add teacher");
      // alert(err.response.data.message)
      // console.log(err.response.data.message);
      // setSnackbarMessage(err.response?.data?.message || "Failed to add teacher");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
 
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setError(null);
  };

  const handleCancel = () => {
    navigate('/teachers')
  }
 
  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Add New Teacher
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={addTeacher}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" variant="outlined" required sx={{width:"100%"}}>
              <InputLabel>Teacher Category</InputLabel>
              <Select
                value={newTeacher.teacherCategory}
                onChange={(e) => setNewTeacher({ ...newTeacher, teacherCategory: e.target.value })}
                label="Teacher Category"
                aria-required="true"
              >
                {teacherCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.teacher_name}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" variant="outlined" required sx={{width:"100%"}}>
                <InputLabel>Teacher Sub Category</InputLabel>
                <Select
                  value={newTeacher.teacherSubCategory}
                  onChange={(e) => setNewTeacher({ ...newTeacher, teacherSubCategory: e.target.value })}
                  label="Teacher Sub Category"
                  aria-required="true"
                  disabled={!newTeacher.teacherCategory}  // Disable if no category is selected
                >
                  {filteredSubcategories.map((subCategory) => (
                    <MenuItem key={subCategory._id} value={subCategory._id}>
                      {subCategory.teacher_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* departments */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined" required sx={{width:"100%"}}>
                <InputLabel>Department</InputLabel>
                <Select
                  value={newTeacher.departmentId}
                  onChange={(e) => setNewTeacher({ ...newTeacher, departmentId: e.target.value })}
                  label="Department"
                  aria-required="true"
                >
                  {/* {departments.map((department, index) => (
                    <MenuItem key={index} value={department._id}>
                      {department.department_name}
                    </MenuItem>
                  ))} */}
                 {departments.map((department, index) => (
                  <MenuItem key={index} value={department.department_id}>
                    {department.department_name} {/* Display the department name */}
                   </MenuItem>
                  ))}
 
 
                </Select>
              </FormControl>
            </Grid>
         
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                value={newTeacher.name}
                onChange={(e) => setNewTeacher({ ...newTeacher, name: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined" required>
                <InputLabel>Qualification</InputLabel>
                <Select
                  value={newTeacher.qualification}
                  onChange={(e) => setNewTeacher({ ...newTeacher, qualification: e.target.value })}
                  label="Qualification"
                  aria-required="true"
                >
                  {qualifications.map((qualification, index) => (
                    <MenuItem key={index} value={qualification}>
                      {qualification}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mobile"
                type="text"
                value={newTeacher.mobile}
                onChange={(e) => setNewTeacher({ ...newTeacher, mobile: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                inputProps={{ pattern: "[0-9]{10}" }}
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                type="email"
                value={newTeacher.email}
                onChange={(e) => setNewTeacher({ ...newTeacher, email: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Hire Date"
                type="date"
                value={newTeacher.hire_Date}
                onChange={(e) => setNewTeacher({ ...newTeacher, hire_Date: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
                aria-required="true"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Profile Image
              </Typography>
              <Button variant="contained" component="label">
                Upload Profile Image
                <input type="file" hidden onChange={handleImageChange} />
              </Button>
              {profileImage && (
                <div>
                  <Typography>{profileImage.name}</Typography>
                  <img
                    src={profileImagePreview}
                    alt="Profile Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginTop: "10px",
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Add Teacher
              </Button>
              <Button type="button" variant="contained" onClick={handleCancel} color="primary" sx={{mx:2, backgroundColor:'#e0e0e0'}}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
 
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};
 
export default AddTeacher;