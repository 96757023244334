import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";

const data = [
  { id: 1, name: "Student 1" },
  // { id: 2, name: "Student 2" },
  // { id: 3, name: "Student 3" },
  // { id: 4, name: "Student 4" },
  // { id: 5, name: "Student 5" },
  // { id: 6, name: "Student 6" },
  // Add more student data as needed
];

const actions = ["Reports", "Comments", "Projects", "Quiz", "Attendance"];

const buttonColors = {
  Reports: "#f9d77b",
  Comments: "#8cd9a1",
  Projects: "#84c7e8",
  Quiz: "#f3b0be",
  Attendance: "#e8e8e8",
};

const GradesAssigned = () => {
  return (
    <Box sx={{ padding: 3 }}>
      {/* Grades Assigned Header */}
      <Typography
        variant="h6"
        component="div"
        sx={{
          marginBottom: 2,
          fontWeight: "bold",
          backgroundColor: "#e8e8e8",
          padding: "10px",
        }}
      >
        GRADES ASSIGNED
      </Typography>

      {/* Select Grade and Division */}
      {/* <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}> */}
        {/* <Select
          displayEmpty
          defaultValue=""
          sx={{ width: "300px", height: "45px" }}
        > */}
          {/* <MenuItem value="">Select Grade</MenuItem>
          <MenuItem value={1}>Grade 1</MenuItem>
          <MenuItem value={2}>Grade 2</MenuItem> */}
          {/* Add more grades as needed */}
        {/* </Select> */}
        {/* <Select
          displayEmpty
          defaultValue=""
          sx={{ width: "300px", height: "45px" }}
        > */}
          {/* <MenuItem value="">Select Division</MenuItem>
          <MenuItem value={1}>Division A</MenuItem>
          <MenuItem value={2}>Division B</MenuItem> */}
          {/* Add more divisions as needed */}
        {/* </Select> */}
      {/* </Box> */}

      {/* Student List */}
      <TableContainer>
        <Table sx={{ width: "80%" }}>
          <TableBody>
            {data.map((student) => (
              <TableRow key={student.id}>
                <TableCell>
                  {student.id}. {student.name}
                </TableCell>
                <TableCell sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* Action Buttons with 5px gap */}
                  {actions.map((action) => (
                    <Button
                      key={action}
                      variant="contained"
                      sx={{
                        backgroundColor: buttonColors[action],
                        color: "#000",
                        "&:hover": {
                          backgroundColor: buttonColors[action],
                          opacity: 0.8,
                        },
                        // fontWeight: "bold",
                        padding: "6px 12px", // Adjust padding as needed
                        minWidth: "80px", // Ensures buttons are the same size
                        marginLeft: "5px", // Adds 5px gap between buttons
                      }}
                    >
                      {action}
                    </Button>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GradesAssigned;
