import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Button, Box, Grid, Typography, LinearProgress, TablePagination } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom"; // UseNavigate from react-router-dom
import { getToken } from "../../../utils/AuthUtils";
 


function OngoingCourses() {
  const [view, setView] = useState("ongoing");
  const [courses1, setCourses1] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate(); // UseNavigate instead of useHistory
 
 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [user, setUser] = useState(null); // State to hold user information
  const [userdata, setData] = useState(null); // To store fetched data
  const [divisionname, setDivisionname] = useState(null); // To store fetched data
  const [gradename, setGradename] = useState(null); // To store fetched data

 


  const handleRedirect = (gradename,courseId,gradeidd,gradedivisionname,schoolidd) => {
    navigate(`/student/ongoing_courses_chapter/${courseId}`, {
      state: {gradename, gradeidd,gradedivisionname,schoolidd },
    });
  };
   // Check for token on mount and redirect if not present
   useEffect(() => {
    const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

    if (!token) {
      navigate("/login");
    } else {
      try {
        const decoded = jwtDecode(token); // Decode the token
        console.log(
          "dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd",
          decoded
        ); // Log the decoded token to the console

        setUser(decoded); // Set user information in state
        const id = decoded.reg_id; // Extract reg_id from the decoded token
        fetchStudentData(id);
      } catch (error) {
        console.error("Token decoding failed", error);
        navigate("/login"); // Redirect if decoding fails
      }
    }
  }, [navigate]);

  const fetchStudentData = async (id) => {
    try {
      const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student/fetch_grade/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(response.data.data);
      // Extract division part from grade_division
const gradeDivision = response.data.data.grade_division; // Assuming grade_division is part of the response
const gname = gradeDivision.split(" ").slice(0, 2).join(" ");

const division = gradeDivision.split(" ")[gradeDivision.split(" ").length - 1]; // Get the last part (e.g., "A")

// Set the division in state
      setDivisionname(division); setGradename(gname);

      console.log(
        "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        response.data.data
      );
    } catch (error) {
      console.error("Error fetching student data", error);
    }
  };
  useEffect(() => {
    fetchongoingcourse();
  }, [page, rowsPerPage, searchTerm]);
 
  const fetchongoingcourse = async () => {
    const token = getToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/ongoing-course?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          }
        }
        // `${process.env.REACT_APP_BACKEND}/api/teacher?page=${page}&limit=${rowsPerPage}&search=${searchTerm}`
      );
     
     
     
      const data = await response.json();
      console.log(data);
      if (data?.data) {
        const courseSerial = data.data.map((item, index) => ({
          ...item,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !item.status, // Add this line
        }));
        setCourses1(courseSerial);
        setSerialNumbers(courseSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };
 
 
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
 
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0); // Reset to first page on rows per page change
  };
 
  return (
    <Box p={2} sx={{ width: "70%" }}>
      {/* Buttons */}
      {/* <Box display="flex" justifyContent="start" mb={3}>
        <Button
          variant="contained"
          onClick={() => setView("ongoing")}
          startIcon={<ArrowForwardIcon />}
          style={{
            marginRight: "10px",
            backgroundColor: view === "ongoing" ? "black" : "orange",
            color: view === "ongoing" ? "white" : "black",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          Ongoing Courses
        </Button>
        <Button
          variant="contained"
          onClick={() => setView("completed")}
          startIcon={<ArrowForwardIcon />}
          style={{
            backgroundColor: view === "completed" ? "black" : "orange",
            color: view === "completed" ? "white" : "black",
            borderRadius: "5px",
            fontWeight: "bold",
            fontSize: "15px",
          }}
        >
          Completed Courses
        </Button>
      </Box> */}
 
      {/* Courses List */}
      <Grid container spacing={0}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{ marginBottom: "15px" }}
      >
    
    
        <Grid item xs={2.4}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#e4443f",
              color: "#fff !important",
              padding: "10px",
              fontSize: "27px",
              borderRadius: "4px",
            }}
          >

            {userdata ? userdata.grade_division : "Loading..."}{" "}
            {/* Use userdata.grade_id */}          </Typography>

         
        </Grid>
      </Grid>
        {courses1.map((course) => (
          
          <Grid item xs={12} key={course._id}>
            <Box display="flex" alignItems="center" mb={1}>
              {/* Course Number */}
              <Box
                bgcolor="black"
                color="white"
                p={1}
                width={40}
                textAlign="center"
                mr={2}
                style={{
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                {course.serial}
              </Box>
 
              {/* Grade Image */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={100}
                mr={2}
              >
                <img
                  src={course.course_thumbnail}
                  alt={course.grade}
                  style={{ width: "55%", height: "auto", borderRadius: "5px" }}
                />
              </Box>
 
              {/* Title */}
              <Box
                flexGrow={1}
                bgcolor="#fde0a9"
                p={1}
                textAlign="left"
                mr={2}
                style={{
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                {/* {course.title} */}
                {course.courses_name}
              </Box>
 
              {/* Clickable Icon for Redirection */}
              <Box ml={2}>
                <Box
                  bgcolor="#e4443f"
                  p={1}
                  borderRadius="0%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => handleRedirect(gradename,course._id,userdata.grade_id,divisionname,userdata.school_id)}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowForwardIcon style={{ color: "white" }} />
                </Box>
              </Box>
            </Box>
 
            {/* Progress Bar */}
            <Box mt={1} mb={2} ml={22} mr={10}>
              <LinearProgress
                variant="determinate"
                value={course.progress}
                style={{ height: "5px", borderRadius: "0px" }}
              />
              <Typography
                mt={1}
                textAlign="right"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                {course.progress}%
              </Typography>
            </Box>
          </Grid>
 
         
        ))}
      </Grid>
      {totalItems > 5 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  );
}
 
export default OngoingCourses;