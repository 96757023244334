import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  Avatar,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import Dashimg from "../../assets/dshimg.jpg";
import Right from "../../assets/right.png";
import Down from "../../assets/down.png";
import Robo from "../../assets/Robo.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CommentIcon from "@mui/icons-material/Comment";
import { MdCampaign } from "react-icons/md";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import StarIcon from "@mui/icons-material/Star";

const circleData = [
  { title1: "COMPLETED", title2: "LESSONS", value1: 11, value2: 36 },
  { title1: "APPROVED", title2: "PROJECTS", value1: 2, value2: 8 },
  { title1: "AVERAGE SCORE", title2: "ASSESSMENTS", value1: 67, value2: 34 },
  { title1: "AVERAGE SCORE", title2: "QUIZZES", value1: 87, value2: 12 },
];

const CircleCard = ({ title1, title2, value1, value2 }) => {
  return (
    <Box
      sx={{
        width: { xs: "130px", sm: "200px", md: "200px", lg: "150px" },
        height: { xs: "130px", sm: "200px", md: "200px", lg: "150px" },
        position: "relative",
        marginX: { xs: "8px", sm: "10px", md: "0px", lg: "15px" },
        marginTop: { xs: "10px", sm: "20px", md: "0px", lg: "20px" },
        padding: "10px",
        display: "inline-block",
      }}>
      {/* Full circle */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: {
            xs: "8px solid #fbe8df",
            sm: "15px solid #fbe8df",
          },
          position: "relative",
        }}>
        {/* Top half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#000",
            borderTopLeftRadius: "90px",
            borderTopRightRadius: "90px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
          }}>
          <Typography
            variant="h6"
            color="#fff!important"
            sx={{
              fontSize: { xs: "18px", sm: "28px", md: "26px", lg: "20px" },
              fontWeight: "300",
              lineHeight: 1.2,
            }}>
            {value1}
          </Typography>
          <Typography
            variant="body2"
            color="#ff9933"
            sx={{
              fontSize: { xs: "9px", sm: "15px", md: "15px", lg: "10px" },
              textTransform: "uppercase",
              textAlign: "center",
              lineHeight: 1.2,
            }}>
            {title1}
          </Typography>
        </Box>

        {/* Bottom half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#bb4c4c",
            borderBottomLeftRadius: "90px",
            borderBottomRightRadius: "90px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
          }}>
          <Typography
            variant="body2"
            color="#fff!important"
            sx={{
              fontSize: { xs: "10px", sm: "15px", md: "15px", lg: "10px" },
              textTransform: "uppercase",
              textAlign: "center",
              lineHeight: 1.2,
            }}>
            {title2}
          </Typography>

          <Typography
            variant="h6"
            color="#fff!important"
            sx={{
              fontSize: { xs: "18px", sm: "26px", md: "26px", lg: "24px" },
              fontWeight: "400",
              lineHeight: 1.2,
            }}>
            {value2}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const messages = [
  {
    id: 1,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "These questions aim to assess student engagement, ",
  },
  {
    id: 2,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: "learning outcomes, and interest in robotics,",
  },
  {
    id: 3,
    user: "Manjusha",
    time: "Sept 03 12:30 PM",
    text: " providing valuable feedback for improving the program.",
  },
];
const MessageCard = ({ user, time, text, isReply }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isReply ? "row-reverse" : "row",
        justifyContent: isReply ? "flex-end" : "flex-start",
        marginBottom: 2,
        alignItems: "flex-start",
      }}>
      <Avatar
        alt={user}
        src="https://randomuser.me/api/portraits/women/65.jpg"
        sx={{
          width: { xs: 40, sm: 48 },
          height: { xs: 40, sm: 48 },
          margin: isReply ? "0 0 0 16px" : "0 16px 0 0",
        }}
      />

      <Box sx={{ maxWidth: { xs: "70%", sm: "75%" } }}>
        {/* Name and Timestamp */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginBottom: 0.5,
          }}>
          <Typography variant="subtitle2" fontWeight="bold">
            {user}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {time}
          </Typography>
        </Box>

        {/* Message Box */}
        <Box
          sx={{
            padding: 2,
            backgroundColor: isReply ? "#d1ffd6" : "#fff",
            borderRadius: "12px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            borderLeft: isReply ? "none" : "4px solid #FF6F61",
            borderRight: isReply ? "4px solid #FF6F61" : "none",
            wordWrap: "break-word",
          }}>
          <Typography variant="body2" color="text.primary">
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const TeacherDashboard = () => {
  const [view, setView] = useState("ongoing");

  return (
    <Container maxWidth="xl" sx={{ paddingY: 4, mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={7}>
          <img
            src={Dashimg}
            alt="Dashboard Image"
            style={{
              width: "100%",
              height: { xs: "200px", sm: "250px", md: "300px" },
              borderRadius: "18px",
            }}
          />
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{
                borderRadius: "20px",
                border: "1px solid #fbe0d5",
                height: { xs: "auto", sm: "auto", md: "auto" },
                textAlign: "center",
                width: "100%",
                p: { xs: 2, sm: 3, md: 4 },
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
                {circleData.map((circle, index) => (
                  <Grid item xs={6} sm={6} md={6} lg={3} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}>
                      <CircleCard
                        title1={circle.title1}
                        title2={circle.title2}
                        value1={circle.value1}
                        value2={circle.value2}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column", // Adjust to column for better responsiveness
              borderRadius: 2,
              backgroundColor: "#fce3d7",
              padding: { xs: 2, sm: 3, md: 4 },
              height: "auto", // Let height adapt to content
              alignItems: "center",
              marginTop: { xs: "15px", sm: "20px", md: "25px" },
              marginBottom: { xs: "15px", sm: "20px", md: "25px" },
              overflow: "visible", // Ensure overflow is visible for inner cards
            }}>
            {/* Main Content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                width: "100%",
              }}>
              {/* Left Side Icon Box */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f44e3b",
                  borderRadius: "8px",
                  padding: { xs: 1.5, sm: 2, md: 1, lg: 2 },
                  width: { xs: 80, sm: 100, md: 100, lg: 120 },
                  height: { xs: 80, sm: 100, md: 80, lg: 120 },
                }}>
                <Typography
                  component="span"
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "32px",
                      sm: "40px",
                      md: "50px",
                      lg: "70px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <MdCampaign
                    sx={
                      {
                        // FontSize: { xs: 80, sm: 100, md: 100, lg: 250 },
                      }
                    }
                  />
                </Typography>
              </Box>

              {/* Right Side Text and Image */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  ml: { xs: 2, sm: 3, md: 1, lg: 4 },
                  width: "100%",
                  alignItems: "center",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    mr: { xs: 0, sm: 2, md: 1, lg: 3 },
                    alignItems: "center", // Center text for smaller screens
                  }}>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      fontSize: {
                        xs: "18px",
                        sm: "20px",
                        md: "15px",
                        lg: "22px",
                      },
                      textAlign: { xs: "center", sm: "left" },
                    }}>
                    Robo Roundups
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "13px",
                        lg: "18px",
                      },
                    }}>
                    Latest in Robotics
                  </Typography>
                </Box>

                {/* Image */}
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: 150, sm: 200, md: 200, lg: 250 },
                    height: { xs: 75, sm: 100, md: 125, lg: 150 },
                    borderRadius: 2,
                    ml: { xs: "0", sm: 0 },
                    mt: { xs: 2, sm: 0 },
                    display: "flex",
                  }}
                  image={Robo}
                  alt="Robots working"
                />
              </Box>
            </Box>
          </Card>
          {/* Two Bottom Cards - Aligned outside the main card */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" }, // Single column on small screens
              gap: 2,
              marginTop: 3, // Position below main card
              width: "100%", // Full width for better layout
            }}>
            {/* First Inner Card */}
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                boxShadow: 3,
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
              }}>
              <CardMedia
                component="img"
                sx={{
                  width: { xs: 40, sm: 50 },
                  height: { xs: 40, sm: 50 },
                  borderRadius: "50%",
                }}
                image={Dashimg}
                alt="First Card Image"
              />
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6">First Card Title</Typography>
                <Typography variant="body2">
                  This is the first inner card paragraph. It has two lines of
                  text.
                </Typography>
              </Box>
            </Box>

            {/* Second Inner Card */}
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                boxShadow: 3,
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
              }}>
              <CardMedia
                component="img"
                sx={{ width: 50, height: 50, borderRadius: "50%" }}
                image={Dashimg}
                alt="Second Card Image"
              />
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6">Second Card Title</Typography>
                <Typography variant="body2">
                  This is the second inner card paragraph. It has two lines of
                  text.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Second column with xs={4} */}
        <Grid item xs={12} md={5} lg={5}>
          <Grid
            container
            mb={3}
            sx={{
              backgroundColor: "#f37c1f",
              padding: { xs: "20px", sm: "20px", md: "25px", lg: "50px" },
              borderRadius: "20px",
              height: "auto",
              display: "flex",
              justifyContent: "center",
            }}>
            <Box
              display="flex"
              justifyContent={{ xs: "center", sm: "space-between" }}
              alignItems="center"
              flexDirection={{ xs: "column", sm: "row" }}>
              <Button
                variant="contained"
                onClick={() => setView("ongoing")}
                sx={{
                  marginRight: { xs: 0, sm: "10px" },
                  marginBottom: { xs: "10px", sm: 0 },
                  backgroundColor: "orange",
                  color: "black",
                  borderRadius: "5px",
                  fontWeight: "bold",
                  fontSize: { xs: "14px", sm: "15px", md: "10px", lg: "15px" },
                  padding: "10px",
                  "& img": {
                    marginRight: "8px",
                    width: { xs: "30px", sm: "40px", md: "25px", lg: "40px" },
                    height: { xs: "30px", sm: "40px", md: "25px", lg: "40px" },
                  },
                }}>
                <img src={Right} alt="Ongoing Courses" />
                Ongoing Courses
              </Button>
              <Button
                variant="contained"
                onClick={() => setView("completed")}
                sx={{
                  marginRight: { xs: 0, sm: "10px" },
                  backgroundColor: "black",
                  color: "#fff!important",
                  borderRadius: "5px",
                  // fontWeight: "bold",
                  fontSize: { xs: "14px", sm: "15px", md: "10px", lg: "15px" },
                  padding: "10px",
                  "& img": {
                    marginRight: "8px",
                    width: { xs: "30px", sm: "40px", md: "25px", lg: "40px" },
                    height: { xs: "30px", sm: "40px", md: "25px", lg: "40px" },
                  },
                }}>
                <img src={Down} alt="Completed Courses" />
                Completed Courses
              </Button>
            </Box>
          </Grid>

          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ marginBottom: 4 }}>
            {/* Icon in the first column */}
            <Grid item xs={2}>
              <Box
                sx={{
                  backgroundColor: "#FF6F61",
                  padding: "8px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <HistoryEduOutlinedIcon sx={{ color: "#fff", fontSize: 32 }} />
              </Box>
            </Grid>

            {/* Title and Stars in the second column */}
            <Grid item xs={10}>
              <Box
                sx={{
                  backgroundColor: "#FF6F61",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  gap: 1, // Space between title and stars
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff !important",
                    marginRight: 1,
                  }}>
                  Teacher's Remarks
                </Typography>
                {/* 5 Rating Stars */}
                {[...Array(5)].map((_, index) => (
                  <StarIcon sx={{ color: "#fff", fontSize: 13 }} />
                ))}
              </Box>
            </Grid>
          </Grid>
          {/* ); */}

          {/* const TeacherRemarks = () => ( */}
          <Grid sx={{ backgroundColor: "#fbe0d5", padding: "20px" }}>
            {/* Header Section */}
            {/* <TeacherRemarksHeader /> */}

            {/* Messages Section */}
            <Box>
              {messages.map((msg, index) => (
                <MessageCard
                  key={msg.id}
                  user={msg.user}
                  time={msg.time}
                  text={msg.text}
                  isReply={index % 2 === 1} // Assuming alternating between sent and received
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeacherDashboard;
