import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../../utils/AuthUtils';
 
const AddDepartment = () => {
  const [formValues, setFormValues] = useState({
    department_id: '',
    department_name: '',
    contact_no: '',
    email_address: '',
  });
  const navigate = useNavigate();
 
  const handleFormSubmit = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/department`, formValues,
        {
          headers:{
            Authorization:`Bearer ${getToken()}`
          }
        }
      );
      navigate('/department_list');
    } catch (err) {
      console.error('Error adding department:', err);
    }
  };
 
  return (
    <Box padding={2}>
      <h2>Add Assigned Department</h2>
      <TextField
        margin="dense"
        label="Department ID"
        fullWidth
        value={formValues.department_id}
        onChange={(e) => setFormValues({ ...formValues, department_id: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Department Name"
        fullWidth
        value={formValues.department_name}
        onChange={(e) => setFormValues({ ...formValues, department_name: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Contact No"
        fullWidth
        value={formValues.contact_no}
        onChange={(e) => setFormValues({ ...formValues, contact_no: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Email Address"
        fullWidth
        value={formValues.email_address}
        onChange={(e) => setFormValues({ ...formValues, email_address: e.target.value })}
      />
      <Box marginTop={2}>
        <Button variant="contained" color="primary" onClick={handleFormSubmit}>
          Add Department
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
 
export default AddDepartment;