import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  Typography,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";

const AssignProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [productCategory, setProductCategory] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState([]);
  const [selectedProductSubCategory, setSelectedProductSubCategory] = useState("");

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  useEffect(() => {
    const message = sessionStorage.getItem("AddAssignProductSuccessMessage");
    if (message) {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      sessionStorage.removeItem("AddAssignProductSuccessMessage"); // Remove the message after showing
    }
    fetchProducts();
  }, [page, rowsPerPage, searchTerm, startDate, endDate]);

  const fetchProducts = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("unauthorized user");
        return;
      }
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND
        }/api/assign_product/allassignproduct?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.data);

      if (data?.data) {
        const assignProductWithSerial = data.data.map((product, index) => ({
          ...product,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !product.status, // Add this line
        }));
        setProducts(assignProductWithSerial);
        setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No assign products found");
      }
    } catch (err) {
      console.error("Error assign products:", err);
      setError("Failed to fetch assign products");
    } finally {
      setLoading(false);
    }
  };

  // department category select list
  useEffect(() => {
    const fetchProductCategory = async () => {
      // if (!selectedDeptCategory) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_product/getallproductcategory`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setProductCategory(data.data || []);
          console.log("teachers related department: ", data.data);
          // setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchProductCategory();
  }, []);

    // department sub category select list
    useEffect(() => {
      console.log("dept category: ", selectedProductCategory);
      
      const fetchProductSubCategory = async () => {
        if (!selectedProductCategory) return;
        try {
          const token = getToken();
          if (!token) {
            console.log("unauthorized user");
            return;
          }
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/assign_product/getallproductsubcat/${selectedProductCategory._id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.ok) {
            setProductSubCategory(data.data || []);
            console.log("teachers related department: ", data.data);
            // setNewDepartment.department_id = "";
          }


          const response1 = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/assign_product/category/${selectedProductCategory._id}?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              }
            }
          );
          const data1 = await response1.json();
          console.log(data1.data);
    
          if (data1?.data) {
            const assignProductWithSerial = data1.data.map((product, index) => ({
              ...product,
              serial: page * rowsPerPage + index + 1,
              statusToggle: !product.status, // Add this line
            }));
            setProducts(assignProductWithSerial);
            setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
            setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
          } else {
            setError("No assign departments found");
          }
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      };
      fetchProductSubCategory();
    }, [selectedProductCategory]);

    useEffect(() => {
      console.log("dept sub category: ", selectedProductSubCategory);
      
      const fetchProductbyproductSubCat = async () => {
        if (!selectedProductSubCategory) return;
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/assign_product/subcategory/${selectedProductSubCategory._id}?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              }
            }
          );
          const data = await response.json();
          console.log(data.data);
    
          if (data?.data) {
            const assignProductWithSerial = data.data.map((product, index) => ({
              ...product,
              serial: page * rowsPerPage + index + 1,
              statusToggle: !product.status, // Add this line
            }));
            setProducts(assignProductWithSerial);
            setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
            setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
          } else {
            setError("No assign departments found");
          }
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      };
      fetchProductbyproductSubCat();
    }, [selectedProductSubCategory]);

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteTeacher = async () => {
   
    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!");
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_product/delete/${deleteId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
      setSnackbarMessage("Assigned product deleted successfully");
      setOpenSnackbar(true);
      fetchProducts();
      handleCloseDeleteDialog();
    }
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateProductStatus(id, newStatus); // Update parent status in the backend
  };
  const updateProductStatus = async (id, status) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!");
      }
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_product/updatestatus/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("product status updated successfully");
      setOpenSnackbar(true);
      fetchProducts(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };

  const moveToAssignProduct = () => {
    navigate("/school/assign-product");
  };

  // const editNavigation = (teacher)=>{
  //   navigate(`school/edit-assigned-course/${teacher._id}`)
  // }
  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#e0e0e0"
        p={1}
        mt={2}>
        <Typography variant="h4">Assigned Product List</Typography>
      </Box>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          padding={2}
          gap={1}
          sx={{ paddingRight: "0", paddingLeft: "0" }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "350px" }}
          />
          <Autocomplete
              options={productCategory}
              getOptionLabel={(option) =>
                option.product_category_name
                  ? option.product_category_name
                  : "N/A"
              }
              value={
                productCategory.find((item) => item._id === selectedProductCategory) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = productCategory.find(
                    (item) => item._id === newValue._id
                  );
                 
                  setSelectedProductCategory(
                    selected
                  );
                } else {
                  setSelectedProductCategory([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select product category" />
              )}
              sx={{ width: 350 }}
            />

<Autocomplete
              options={productSubCategory}
              getOptionLabel={(option) =>
                option.product_sub_category_name
                  ? option.product_sub_category_name
                  : "N/A"
              }
              value={
                productSubCategory.find((item) => item._id === selectedProductSubCategory) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = productSubCategory.find(
                    (item) => item._id === newValue._id
                  );
                 
                  setSelectedProductSubCategory(
                    selected
                  );
                } else {
                  setSelectedProductSubCategory([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select department sub category" />
              )}
              sx={{ width: 350 }}
            />
          <Box flexGrow={1} /> {/* This will push the button to the right */}
          <Button
            component={Link}
            // to="/school/assigned-course"
            onClick={moveToAssignProduct}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#e0e0e0" }}>
            Assign
          </Button>
        </Box>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Serial No.
              </TableCell>
              <TableCell>
                Resourse Person
              </TableCell>
              <TableCell>
                Department
              </TableCell>
              <TableCell>
                Product Category
              </TableCell>
              <TableCell>
                Product Sub Category
              </TableCell>
              <TableCell>
                Product Name
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 ? (
              products.map((product) => (
                <TableRow key={product._id}>
                  <TableCell>{product.serial}</TableCell>
                  <TableCell>{product.name?product.name.name:"N/A"}</TableCell>
                  <TableCell>{product.department?product.department.department_name:"N/A"}</TableCell>
                  <TableCell>{product.product_category?product.product_category.product_category_name:"N/A"}</TableCell>
                  <TableCell>{product.product_sub_category?product.product_sub_category.product_sub_category_name:"N/A"}</TableCell>
                  <TableCell>{product.product.product_name}</TableCell>
                  <TableCell>
                    <Switch
                      checked={product.status}
                      onChange={() =>
                        handleStatusToggle(product._id, product.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell>
                    {/* <IconButton
                      color="primary"
                      onClick={() => navigate(`${teacher._id}`)}
                      // onClick={() =>editNavigation(teacher)}
                    >
                      <EditIcon />
                    </IconButton> */}
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDeleteDialog(product._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  {/* ... other cells */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">No products available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this assign product?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteTeacher}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssignProductList;
