import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  Grid,
  Box,
  Typography,
  LinearProgress,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { getToken } from "../../../utils/AuthUtils";

const GradeView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null); // State to hold user information
  const [userdata, setData] = useState(null); // To store fetched data
  const [unitsData, setUnitsData] = useState([]);
  const [courseName, setCourseName] = useState("");
  const location = useLocation();

  const {gradename,gradeidd,gradedivisionname,schoolidd} = location.state || {};

  // Check for token on mount and redirect if not present
  useEffect(() => {
    const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

    if (!token) {
      navigate("/login");
    } else {
      try {
        const decoded = jwtDecode(token); // Decode the token
        // console.log(
        //   "dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd",
        //   decoded
        // ); // Log the decoded token to the console

        setUser(decoded); // Set user information in state
        const id = decoded.reg_id; // Extract reg_id from the decoded token
        fetchStudentData(id);
      } catch (error) {
        console.error("Token decoding failed", error);
        navigate("/login"); // Redirect if decoding fails
      }
    }
  }, [navigate]);

  const fetchStudentData = async (id) => {
    try {
      const token = localStorage.getItem("token_key"); // Replace 'authToken' with your actual token key

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/student/fetch_grade/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(response.data.data);
      // console.log(
      //   "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
      //   response.data.data
      // );
    } catch (error) {
      console.error("Error fetching student data", error);
    }
  };

  useEffect(() => {
    const fetchUnitsData = async () => {
      try {
//         const gradeDivision = userdata.grade_division;  // Assuming this is 'Grade 5 A'
// const parts = gradeDivision.split(' ');  // Splits the string by space
// const grade1 = parts.slice(0, -1).join(' ');  // Joins everything except the last part
// const division = parts[parts.length - 1];  // Gets the last part (e.g., "A")

        const unitsResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/unit/${id}`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        const units = unitsResponse.data;

        const combinedUnits = await Promise.all(
          units.map(async (unit) => {

            // console.log('student detailssssss',userdata.grade_id)
            try {
              const unitHandlingResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND}/api/units/handling/student/${unit._id}/${gradeidd}/${gradedivisionname}/${schoolidd}`,
                {
                  headers:{
                    Authorization: `Bearer ${getToken()}`
                  }
                }
              );
              const unitHandling = unitHandlingResponse.data;
              // const unitHandlingResponse = await axios.get(
              //   `${process.env.REACT_APP_BACKEND}/api/units/handling/${unit._id}/${userdata.grade_id}/${division}`
              // );
              // const unitHandling = unitHandlingResponse.data;
              //  console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn",unitHandling)
              return {
                chapterName: unit.chapterName,
                unitid: unit._id,
                unit: unit.unit_name,
                progress: unitHandling.completed || 0,
                isActive: unitHandling.completed >= 100,
                isLocked: unitHandling.completed < 100,
                status: unitHandling.status || 0,
              };
            } catch (error) {
              return {
                chapterName: unit.chapterName,
                unitid: unit._id,
                unit: unit.unit_name,
                progress: 0,
                isActive: false,
                isLocked: true,
                status: 0,
              };
            }
          })
        );

        const groupedUnits = combinedUnits.reduce((acc, unit) => {
          if (!acc[unit.chapterName]) {
            acc[unit.chapterName] = {
              units: [],
              totalProgress: 0,
              unitCount: 0,
              averageProgress: 0,
            };
          }
          acc[unit.chapterName].units.push(unit);
          acc[unit.chapterName].totalProgress += unit.progress;
          acc[unit.chapterName].unitCount += 1;
          acc[unit.chapterName].averageProgress =
            acc[unit.chapterName].totalProgress /
            acc[unit.chapterName].unitCount;
          return acc;
        }, {});

        setUnitsData(groupedUnits);

        const courseResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/unit/coursename/${id}`,
          {
            headers:{
              'Authorization': `Bearer ${getToken()}`
            }
          }
        );
        setCourseName(courseResponse.data.courses_name);
      } catch (error) {
        console.error("Error fetching units data:", error);
      }
    };

    fetchUnitsData();
  }, [id]);

  const handleRedirect = (gradename,unitId,course_name,chapterindex,chapter_namee,unitnamee) => {
    navigate(`/student/unit-interface/${unitId}`, {
      state: { gradename,course_name,chapterindex,chapter_namee,unitnamee },
    });


    // navigate(`/student/ongoing_courses_chapter/${courseId}`); // Use navigate instead of history.push
   
   
  };

  const getUnitBgColor = (progress, isLocked, status) => {
    if (status === 2) return "#ff8214"; // Ongoing unit
    if (isLocked) return "#e0e0e0";
    if (progress === 100) return "#f9f7f7";
    return "#ffa726";
  };

  const getTextColor = (isLocked) => {
    return isLocked ? "#757575" : "#000";
  };

  const calculateCourseProgress = () => {
    let totalProgress = 0;
    let totalUnits = 0;

    Object.values(unitsData).forEach((chapter) => {
      chapter.units.forEach((unit) => {
        totalProgress += unit.progress;
        totalUnits += 1;
      });
    });

    return totalUnits > 0 ? Math.round(totalProgress / totalUnits) : 0;
  };

  if (Object.keys(unitsData).length === 0) {
    return <div>No units available for this course.</div>;
  }

  return (
    <Container maxWidth="xl" sx={{ padding: "20px" }}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{ marginBottom: "5px" }}
      >
        <Grid item xs={1.2}>
          <Button
            variant="contained"
            color="error"
            sx={{
              height: "62px",
              fontSize: "17px",
              color: "#fff !important ",
              marginBottom: "20px",
              width: "95%",
            }}
          >
            {userdata ? userdata.grade_division : "Loading..."}{" "}
            {/* Use userdata.grade_id */}
          </Button>
        </Grid>
        <Grid item xs={7.8}>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#e4443f",
              color: "#fff !important",
              padding: "10px",
              fontSize: "27px",
              borderRadius: "4px",
            }}
          >
            {courseName}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "left", marginTop: "5px" }}>
            <LinearProgress
              variant="determinate"
              value={calculateCourseProgress()}
              sx={{ height: "8px", backgroundColor: "#f0f0f0", flexGrow: 1 }}
            />
            <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
              {calculateCourseProgress()}%
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {Object.entries(unitsData).map(
        ([chapterName, chapterData], chapterIndex) => (
          <React.Fragment key={`chapter-${chapterIndex}`}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ marginBottom: "5px", marginTop: "10px" }}
            >
              <Grid item xs={1.2}>
                <Typography
                  sx={{
                    backgroundColor: "#ffeb99",
                    padding: "10px",
                    fontSize: "18px",
                    marginBottom: "22px",
                    borderRadius: "4px",
                  }}
                >
                  Chapter {chapterIndex + 1}
                </Typography>
              </Grid>
              <Grid item xs={7.8}>
                <Typography
                  sx={{
                    backgroundColor: "#ffeb99",
                    padding: "10px",
                    fontSize: "20px",
                    borderRadius: "4px",
                  }}
                >
                  {chapterName} 
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={chapterData.averageProgress}
                    sx={{
                      height: "5px",
                      backgroundColor: "#f0f0f0",
                      flexGrow: 1,
                    }}
                  />
                  <Typography sx={{ marginLeft: "10px", fontSize: "12px" }}>
                    {chapterData.averageProgress.toFixed(0)}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {chapterData.units.map((unit, index) => (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={`unit-${index}`}
                sx={{ marginBottom: "5px" }}
              >
                <Grid item xs={1.2}>
                  <Typography
                    sx={{
                      backgroundColor: "#ffeb99",
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "4px",
                    }}
                  >
                    Unit {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={7.8}>
                  <Typography
                    sx={{
                      backgroundColor: getUnitBgColor(
                        unit.progress,
                        unit.isLocked,
                        unit.status
                      ),
                      color: getTextColor(unit.isLocked),
                      padding: "10px",
                      borderRadius: "4px",
                      fontSize: "16px",
                    }}
                  >
                    {unit.unit}
                  </Typography>
                </Grid>
                <Grid item xs={0.5}>
                  <Grid item xs={0.5}>
                    <IconButton
                      onClick={() => handleRedirect(gradename,unit.unitid,courseName,chapterIndex + 1,chapterName, unit.unit)}
                      sx={{
                        backgroundColor: unit.isLocked ? "#e0e0e0" : "#f44336",
                        cursor: unit.isLocked ? "not-allowed" : "pointer",
                        borderRadius: "4px",
                        color: "#fff !important",
                      }}
                      disabled={unit.isLocked}
                    >
                      {!unit.isLocked ? <PlayArrowIcon /> : null}
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )
      )}
    </Container>
  );
};

export default GradeView;
