import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControl,
  TableRow,
  Typography,
  Paper,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { getToken } from "../../../utils/AuthUtils";
import { useNavigate } from "react-router-dom";

const actions = ["Reports", "Comments", "Projects", "Quiz", "Attendance"];

const buttonColors = {
  Reports: "#f9d77b",
  Comments: "#8cd9a1",
  Projects: "#84c7e8",
  Quiz: "#f3b0be",
  Attendance: "#e8e8e8",
};

const GradesAssigned = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [grades, setGrades] = useState([]);
  const [students, setStudents] = useState([]);
  const navigate = useNavigate();
  const [gradeAssign, setGradeAssign] = useState({
    grade: "",
    gradeDivision: "",
    name: "",
  });

  useEffect(() => {
    const fetchgradebyteacher = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade_assign/teacherData`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gradeData = response.data.data;
        setGrades(gradeData);

        console.log("gradeData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchgradebyteacher();
    const fetchstudentBygradedivision = async () => {
      if (gradeAssign.grade === "") {
        // If "Select All" is chosen, fetch all students
        fetchStudents();
        return;
      }
      if (!gradeAssign.gradeDivision) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade_assign/gradedivision/${gradeAssign.gradeDivision}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const students = response.data.data;
        setStudents(students);
        // setGradeAssign({
        //   grade: "",
        //   gradeDivision: "",
        //   name: "",
        // });
        if(students){
          setStudents(students);
        }else{
          setStudents([])
        }
        console.log("students: ", response.data.data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };
    fetchstudentBygradedivision();
  }, [gradeAssign.gradeDivision]);

  // useEffect(() => {
    // fetchStudents();
  // }, [gradeAssign]);

  const fetchStudents = async () => {
    // setLoading(true);
    try {
      const token = getToken();
      if (!token) {
        setError("Please login to access students.");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/grade_assign/students`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const data = response.data;
      if (data?.data) {
        setStudents(data.data.students);
      } else {
        setStudents([]); // Reset students if no data is found
        setError("No students found");
      }
    } catch (err) {
      setError("Failed to fetch students");
    } finally {
      setLoading(false);
    }
  };

  const handleActionClick = (action, studentId) => {
    if (action === "Comments") {
      navigate(`/teacher/comments/${studentId}`); // Redirect to /teacher/comments on "Comments" button click
    }
    // You can handle other actions here if needed
  };
  return (
    <Box sx={{ padding: 3 }}>
      {/* Grades Assigned Header */}
      <Typography
        variant="h6"
        component="div"
        sx={{
          marginBottom: 2,
          fontWeight: "bold",
          backgroundColor: "#e8e8e8",
          padding: "10px",
        }}
      >
        Student List
      </Typography>

      {/* Select Grade and Division */}
      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
        <FormControl margin="normal" sx={{ minWidth: 300 }}>
          <InputLabel>Select Grade</InputLabel>
          <Select
            value={gradeAssign.grade || "Select All"}
            
            onChange={(e) => {
               setStudents([]); 
              const selectedGrade = grades.find((item) => item._id === e.target.value);
              if (e.target.value === "Select All") {
                // Reset the grade and gradeDivision when "Select All" is chosen
                setGradeAssign({
                  grade: "",
                  gradeDivision: "",
                });
              } else if (selectedGrade && selectedGrade.grade_id) {
                const gradeDivision = `${selectedGrade.grade_id.gradename || ""} ${selectedGrade.grade_division_id || ""}`;
                setGradeAssign({
                  grade: e.target.value,
                  gradeDivision,
                });

              }
               else {
                setGradeAssign({
                  grade: e.target.value,
                  gradeDivision: "",
                });
              }
            }}
            label="Grade"
            aria-required="true"
          >
            <MenuItem value="Select All">Select All</MenuItem>
            {grades.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.grade_id?.gradename} {item.grade_division_id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Student List */}
      <TableContainer>
        <Table sx={{ width: "80%" }}>
          <TableBody>
            {students.length > 0 ? (
              students.map((student) => (
                <TableRow key={student._id}>
                  <TableCell>
                    {student.id}. {student.name ? student.name : null}
                  </TableCell>
                  <TableCell
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {/* Action Buttons with 5px gap */}
                    {actions.map((action) => (
                      <Button
                        key={action}
                        variant="contained"
                        sx={{
                          backgroundColor: buttonColors[action],
                          color: "#000",
                          "&:hover": {
                            backgroundColor: buttonColors[action],
                            opacity: 0.8,
                          },
                          // fontWeight: "bold",
                          padding: "6px 12px", // Adjust padding as needed
                          minWidth: "80px", // Ensures buttons are the same size
                          marginLeft: "5px", // Adds 5px gap between buttons
                        }}
                        onClick={() => handleActionClick(action, student._id)}
                      >
                        {action}
                      </Button>
                    ))}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography align="center">No Students found for this grade</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GradesAssigned;
