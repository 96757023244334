import React, { useEffect, useState } from 'react';
import { TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../../utils/AuthUtils';

const EditResourcePerson = () => {
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState({
    teacher_id: '',
    name: '',
    qualification: '',
    mobile: '',
    email: '',
    hire_Date: '',
    subject_specialization: ''
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchTeacher(id);
  }, [id]);

  const fetchTeacher = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`,
        {
          Authorization: `Bearer ${getToken()}`
        }
      );
      const data = await response.json();
      if (data?.data) {
        setTeacher(data.data);
        setForm({
          teacher_id: data.data.teacher_id || '',
          name: data.data.name || '',
          qualification: data.data.qualification || '',
          mobile: data.data.mobile || '',
          email: data.data.email || '',
          hire_Date: data.data.hire_Date ? new Date(data.data.hire_Date).toISOString().split('T')[0] : '',
          subject_specialization: data.data.subject_specialization || ''
        });
      } else {
        setError('Teacher not found');
      }
    } catch (err) {
      setError('Failed to fetch teacher');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`
        },
        body: JSON.stringify(form)
      });
      if (response.ok) {
        setSuccess(true);
        setTimeout(() => navigate('/teachers'), 2000); // Redirect after 2 seconds
      } else {
        setError('Failed to update teacher');
      }
    } catch (err) {
      setError('Failed to update teacher');
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(false);
    setError(null);
  };

  // if (loading) {
  //   return <CircularProgress />;
  // }

  return (
    <div>
      <h2>Edit Teacher</h2>
      {error && <div>{error}</div>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Teacher ID"
          name="teacher_id"
          value={form.teacher_id}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Name"
          name="name"
          value={form.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Qualification"
          name="qualification"
          value={form.qualification}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Mobile"
          name="mobile"
          value={form.mobile}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={form.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Hire Date"
          name="hire_Date"
          type="date"
          value={form.hire_Date}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Subject Specialization"
          name="subject_specialization"
          value={form.subject_specialization}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </form>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          Teacher updated successfully
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditResourcePerson;
