import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Grid,
} from "@mui/material";

const Assessment = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const questions = [
    {
      question: "A cell reference means ....................... of the cell.",
      options: [
        { label: "Value", value: "a" },
        { label: "Address", value: "b" },
        { label: "Function", value: "c" },
      ],
    },
    {
      question:
        "In .................. reference, when you copy or move the cell to another cell, the cell reference stays the same.",
      options: [
        { label: "Absolute", value: "a" },
        { label: "Mixed", value: "b" },
        { label: "Relative", value: "c" },
      ],
    },
    {
      question: "This formula uses only one type of operator.",
      options: [
        { label: "Function", value: "a" },
        { label: "Compound", value: "b" },
        { label: "Basic", value: "c" },
      ],
    },
    {
      question: "Each function begins with this sign.",
      options: [
        { label: "#", value: "a" },
        { label: "=", value: "b" },
        { label: "$", value: "c" },
      ],
    },
    {
      question: "Which of the following is a type of statistical function?",
      options: [
        { label: "INT()", value: "a" },
        { label: "COUNT()", value: "b" },
        { label: "COS()", value: "c" },
      ],
    },
  ];

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

  const handleSubmit = () => {
    console.log("Selected Answers:", selectedAnswers);
    // Perform further actions on form submission
  };

  return (
    <Container maxWidth="lg">
      {/* Breadcrumb Section */}
      <Box sx={{ backgroundColor: "#9e503d", height: "50px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "50px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: "18px", color: "#d0bebead !important" }}
            >
              > HOME COURSE 1 GRADE 1 VOL 1> MUSICAL PROGRAMMING: COMPOSING WITH
              MODI MODULES > PERIOD 1
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f1f1",
          height: "100px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          marginBottom: "30px",
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Typography variant="h3" gutterBottom>
            MCQ Type Questions
          </Typography>
        </Container>
      </Box>
      {/* MCQ Section */}
      {questions.map((q, index) => (
        <Box key={index} mb={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>{`${
            index + 1
          }. ${q.question}`}</Typography>
          <RadioGroup
            name={`question-${index}`}
            value={selectedAnswers[index] || ""}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
          >
            {q.options.map((option, i) => (
              <FormControlLabel
                key={i}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </Box>
      ))}

      {/* Submit Button */}
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Answers
        </Button>
      </Box>
    </Container>
  );
};

export default Assessment;
