import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  Container, Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";

const AssignedDepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deptCategory, setDeptCategory] = useState([]);
  const [selectedDeptCategory, setSelectedDeptCategory] = useState("");
  const [deptSubCategory, setDeptSubCategory] = useState([]);
  const [selectedSubDeptCategory, setSelectedSubDeptCategory] = useState("");

  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);


  useEffect(() => {
    fetchDepartments();

  }, [page, rowsPerPage, searchTerm]);

  const fetchDepartments = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("unauthorized user");
        return;
      }
      console.log("");

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_department/assigndepartment?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        }
      );
      const data = await response.json();
      console.log(data.data);

      if (data?.data) {
        const assignProductWithSerial = data.data.map((product, index) => ({
          ...product,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !product.status, // Add this line
        }));
        setDepartments(assignProductWithSerial);
        setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No assign departments found");
      }
    } catch (err) {
      console.error('Error assign departments:', err);
      setError("Failed to fetch assign departments");
    } finally {
      setLoading(false);
    }
  };

  // department category select list
  useEffect(() => {
    const fetchDeptCategory = async () => {
      // if (!selectedDeptCategory) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_department/getalldepartmentcategory`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setDeptCategory(data.data || []);
          console.log("teachers related department: ", data.data);
          // setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchDeptCategory();
  }, []);

    // department sub category select list
    useEffect(() => {
      console.log("dept category: ", selectedDeptCategory);
      
      const fetchDeptSubCategory = async () => {
        if (!selectedDeptCategory) return;
        try {
          const token = getToken();
          if (!token) {
            console.log("unauthorized user");
            return;
          }
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/assign_department/getdepartmentsubcategoryBycategoryid/${selectedDeptCategory._id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.ok) {
            setDeptSubCategory(data.data || []);
            console.log("teachers related department: ", data.data);
            // setNewDepartment.department_id = "";
          }


          const response1 = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/assign_department/assigndepartment/${selectedDeptCategory._id}?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              }
            }
          );
          const data1 = await response1.json();
          console.log(data1.data);
    
          if (data1?.data) {
            const assignProductWithSerial = data1.data.map((product, index) => ({
              ...product,
              serial: page * rowsPerPage + index + 1,
              statusToggle: !product.status, // Add this line
            }));
            setDepartments(assignProductWithSerial);
            setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
            setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
          } else {
            setError("No assign departments found");
          }
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      };
      fetchDeptSubCategory();
    }, [selectedDeptCategory]);

    useEffect(() => {
      console.log("dept sub category: ", selectedSubDeptCategory);
      
      const fetchDeptbydeptsubcat = async () => {
        if (!selectedSubDeptCategory) return;
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/assign_department/assigndepartment1/${selectedSubDeptCategory._id}?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              }
            }
          );
          const data = await response.json();
          console.log(data.data);
    
          if (data?.data) {
            const assignProductWithSerial = data.data.map((product, index) => ({
              ...product,
              serial: page * rowsPerPage + index + 1,
              statusToggle: !product.status, // Add this line
            }));
            setDepartments(assignProductWithSerial);
            setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
            setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
          } else {
            setError("No assign departments found");
          }
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      };
      fetchDeptbydeptsubcat();
    }, [selectedSubDeptCategory]);


  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteDepartment = async () => {
    

    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!")
      }
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/assign_department/delete-assign-department/${deleteId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        setSnackbarMessage("Assign course deleted successfully");
        setOpenSnackbar(true);
        fetchDepartments();
        handleCloseDeleteDialog();
      }
      
      
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };


  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateDepartmentStatus(id, newStatus); // Update parent status in the backend
  };
  const updateDepartmentStatus = async (id, status) => {
    try {
      const token = getToken();
      if (!token) {
        setError("Access denied!")
      }
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assign_department/${id}/status_assign_department`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("assigned department status updated successfully");
      setOpenSnackbar(true);
      fetchDepartments(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update assigned department status");
    }
  };

  const moveToAssignProduct = () => {
    navigate("/school/assign-department")
  }

  // const editNavigation = (teacher)=>{
  //   navigate(`school/edit-assigned-course/${teacher._id}`)
  // }
  return (
    <Container maxWidth='100%'>
      <Box
        padding={1}
        display="flex"
        justifyContent="space-between"
        backgroundColor="#e0e0e0"
      >
        <Typography variant="h4" component="h1" >
          Assigned Department List
        </Typography>
      </Box>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" padding={2} gap={1} sx={{ paddingRight: '0', paddingLeft: '0' }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "350px" }}
          />

{/* <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Department Category</InputLabel>
            <Select
              value={selectedDeptCategory}
              onChange={(e) =>
                setSelectedDeptCategory(
                  e.target.value
                )
              }
              label="Teacher Category"
              aria-required="true"
            >
              {deptCategory.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.department_category_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

<Autocomplete
              options={deptCategory}
              getOptionLabel={(option) =>
                option.department_category_name
                  ? option.department_category_name
                  : "N/A"
              }
              value={
                deptCategory.find((item) => item._id === selectedDeptCategory) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = deptCategory.find(
                    (item) => item._id === newValue._id
                  );
                 
                  setSelectedDeptCategory(
                    selected
                  );
                } else {
                  setSelectedDeptCategory([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select department category" />
              )}
              sx={{ width: 350 }}
            />

<Autocomplete
              options={deptSubCategory}
              getOptionLabel={(option) =>
                option.department_sub_category_name
                  ? option.department_sub_category_name
                  : "N/A"
              }
              value={
                deptCategory.find((item) => item._id === selectedSubDeptCategory) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = deptSubCategory.find(
                    (item) => item._id === newValue._id
                  );
                 
                  setSelectedSubDeptCategory(
                    selected
                  );
                } else {
                  setSelectedSubDeptCategory([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select department sub category" />
              )}
              sx={{ width: 350 }}
            />

          <Box flexGrow={1} /> {/* This will push the button to the right */}
          <Button
            component={Link}
            // to="/school/assigned-course"
            onClick={moveToAssignProduct}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Assign
          </Button>
        </Box>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Serial
              </TableCell>
              <TableCell>
                Department Id
              </TableCell>
              <TableCell>
                Department name
              </TableCell>
              <TableCell>
                Department category
              </TableCell>
              <TableCell>
                Department sub category
              </TableCell>
              <TableCell>
                Teacher
              </TableCell>
              <TableCell>
                Lab Name
              </TableCell>
              <TableCell>
                Lab location
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.length > 0 ? (
              departments.map((department) => (
                <TableRow key={department._id}>
                  <TableCell>{department.serial}</TableCell>
                  <TableCell>{department.Assigned_department_id}</TableCell>
                  <TableCell>{department.department?department.department.department_name:"N/A"}</TableCell>
                  <TableCell>{department.department_category?department.department_category.department_category_name:"N/A"}</TableCell>
                  <TableCell>{department.department_sub_category?department.department_sub_category.department_sub_category_name:"N/A"}</TableCell>
                  <TableCell>{department.teacher.name}</TableCell>
                  <TableCell>{department.labname.lab_name ? department.labname.lab_name : "No Lab"}</TableCell>
                  <TableCell>{department.lab_location ? department.lab_location : "No Lab"}</TableCell>
                  <TableCell>
                    <Switch
                      checked={department.status}
                      onChange={() =>
                        handleStatusToggle(department._id, department.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  <TableCell>
                    {/* <IconButton
                      color="primary"
                      onClick={() => navigate(`${teacher._id}`)}
                      // onClick={() =>editNavigation(teacher)}
                    >
                      <EditIcon />
                    </IconButton> */}
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDeleteDialog(department._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                  {/* ... other cells */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">No departments available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this assign department?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteDepartment}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssignedDepartmentList;
