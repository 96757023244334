import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  TextField, Button, Typography, MenuItem, Select, FormControl, InputLabel, Box
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

const AddQuestionForm = () => {
  const { assessmentId } = useParams();
  const navigate = useNavigate();

  const [questionText, setQuestionText] = useState("");
  const [questionType, setQuestionType] = useState("MCQ");
  const [options, setOptions] = useState({
    option_text_1: "",
    option_text_2: "",
    option_text_3: "",
    option_text_4: "",
  });
  const [answer, setAnswer] = useState("");
  const [points, setPoints] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the question object
    const question = {
      question_text: questionText.trim(),
      question_type: questionType,
      points: parseInt(points, 10) || 0, // Default to 0 if invalid
      ...options,
      answer: answer.trim(),
    };

    // Prepare the payload with an array of questions and assessment_id
    const payload = {
      questions: [question], // Sending a single question in an array
      assessment_id: assessmentId,
    };

    console.log("Submitting data:", payload); // Log the payload for debugging

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/questions`, payload,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
        }
      }
      );
      console.log("Response:", response.data); // Log the response for debugging
      navigate(`/questions/${assessmentId}`);
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      console.error("Failed to add question", errorMessage);
      alert(`Failed to add question: ${errorMessage}`);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Add New Question
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Question Text"
          value={questionText}
          onChange={(e) => setQuestionText(e.target.value)}
          fullWidth
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Question Type</InputLabel>
          <Select
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
            required
          >
            <MenuItem value="MCQ">MCQ</MenuItem>
            <MenuItem value="Descriptive">Descriptive</MenuItem>
          </Select>
        </FormControl>
        {questionType === "MCQ" && (
          <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: '1fr 1fr' }}>
            <TextField
              label="Option 1"
              value={options.option_text_1}
              onChange={(e) => setOptions({ ...options, option_text_1: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Option 2"
              value={options.option_text_2}
              onChange={(e) => setOptions({ ...options, option_text_2: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Option 3"
              value={options.option_text_3}
              onChange={(e) => setOptions({ ...options, option_text_3: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Option 4"
              value={options.option_text_4}
              onChange={(e) => setOptions({ ...options, option_text_4: e.target.value })}
              fullWidth
              margin="normal"
            />
          </Box>
        )}
        <TextField
          label="Answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Points"
          value={points}
          onChange={(e) => setPoints(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Add Question
        </Button>
      </Box>
    </Box>
  );
};

export default AddQuestionForm;
