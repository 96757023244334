import React from 'react';
import { Navigate } from 'react-router-dom';
import { verifyToken, getRole } from '../../utils/AuthUtils';

function ProtectedParentRoute({ children }) {
    console.log('Verifying token...');
    if (!verifyToken()) {
      console.log('Token is invalid');
      return <Navigate to="/" replace />;
    }
  
    const requiredRole = 'parent';
  
    console.log('Required role:', requiredRole);
    console.log('Detected role:', getRole());
  
    if (requiredRole && getRole() !== requiredRole) {
      console.log('Roles do not match');
      return <Navigate to="/unauthorized" replace />;
    }
  
    console.log('Access granted');
    return children;
  }
  
  export default ProtectedParentRoute;