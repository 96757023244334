import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import {
  Typography,
  Box,
  Button,
  Container,
  CircularProgress,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./EditProject.css";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function EditUnit() {
  const { id } = useParams();
  const [contentData, setContentData] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState(""); // or a valid default value
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setpdfLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [galleryLoading, setGalleryLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();
  // States for image modal
  const [isEditing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [hideFormControl, setHideFormControl] = useState(false);

  useEffect(() => {
    const fetchUnit = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/project/content/${id}`
        );
        console.log("======================", response.data);

        // Check if the response has unitsPlans property
        if (response.data && response.data.projectsContent) {
          // Add "edit": 0 to each item in projects_content
          const updatedUnitsContent =
            response.data.projectsContent.project_content.map((item) => ({
              ...item,
              edit: 0, // Add the edit property
            }));

          // Set the contentData with updated projects_content
          setContentData({
            ...response.data.project,
            projects_content: updatedUnitsContent,
            project_content_id: response.data.projectsContent._id,
          });
        } else {
          setError("No units plans found");
        }
      } catch (err) {
        setError(err.message || "Failed to fetch unit data");
      } finally {
        setLoading(false);
      }
    };
    console.log("updated code", contentData);
    fetchUnit();
    // setContentData(contentDataaaa);
    setLoading(false);
    setpdfLoading(false);
    setGalleryLoading(false);
    setVideoLoading(false);
  }, []);

  const toggleEdit = (index) => {
    const updatedContentData = { ...contentData };

    if (!updatedContentData.projects_content[index].data) {
      alert("Data is empty or not provided!");
      return;
    }

    updatedContentData.projects_content[index].edit =
      updatedContentData.projects_content[index].edit === 0 ? 1 : 0;

    setContentData(updatedContentData);
    console.log("edit data", updatedContentData);

    const anyEditing = updatedContentData.projects_content.some(
      (unit) => unit.edit === 1
    );

    // Set the editing state
    setEditing(anyEditing);
  };

  const toggleEdit1 = () => {
    const updatedContentData = { ...contentData };

    if (!updatedContentData.projects_content[0].data) {
      alert("Data is empty or not provided!");
      return;
    }

    updatedContentData.projects_content[0].edit =
      updatedContentData.projects_content[0].edit === 0 ? 1 : 0;
    setContentData(updatedContentData);
    console.log("edit data", updatedContentData);
    // Check if any edit value is 1
    const anyEditing = updatedContentData.projects_content.some(
      (unit) => unit.edit === 1
    );

    setEditing(anyEditing);
  };
  const handleDelete = (index) => {
    const updatedContentData = { ...contentData }; // Create a copy of the content data
    updatedContentData.projects_content = [
      ...updatedContentData.projects_content,
    ]; // Ensure projects_content is an array

    // Remove the section at the specified index
    updatedContentData.projects_content.splice(index, 1);

    setContentData(updatedContentData); // Update the state with the modified content data
    console.log(updatedContentData); // Log the updated content data
  };

  const handleGalleryDelete = (index, imageIndex) => {
    const updatedContentData = { ...contentData }; // Create a copy of the content data
    updatedContentData.projects_content = [
      ...updatedContentData.projects_content,
    ]; // Ensure projects_content is an array

    // Remove the section at the specified index
    updatedContentData.projects_content[index].data.splice(imageIndex, 1);

    setContentData(updatedContentData); // Update the state with the modified content data
    console.log(updatedContentData); // Log the updated content data
  };

  // Usage example: handleDelete(1); // This will remove the item at index 1

  const handleContentTypeChange = (event, index) => {
    const { value } = event.target;

    setContentData((prevContentData) => {
      // Ensure prevContentData is an object
      if (typeof prevContentData !== "object" || prevContentData === null) {
        console.error("prevContentData is not an object:", prevContentData);
        return contentData; // or handle as needed
      }

      // Make sure projects_content is an array
      const unitsContent = prevContentData.projects_content || [];
      if (!Array.isArray(unitsContent)) {
        console.error("projects_content is not an array:", unitsContent);
        return contentData; // or handle as needed
      }
      // Determine the new section's data based on the selected value
      const newSection =
        value === "pdf" || value === "gallery"
          ? { type: value, data: [""], edit: 1 } // Use an array for pdf and gallery
          : { type: value, data: "", edit: 1 }; // Use an empty string for other types
      const updatedSections = [...unitsContent];
      updatedSections.splice(index + 1, 0, newSection);
      console.log(updatedSections);

      // Return a new object with updated projects_content
      return {
        ...prevContentData,
        projects_content: updatedSections,
      };
    });

    setEditing(true);
  };

  const handleImageContentTypeChange = (index, imageIndex) => {
    // Set the new section's data as an empty string
    const newSection = "";

    setContentData((prevContentData) => {
      // Ensure prevContentData is an object
      if (typeof prevContentData !== "object" || prevContentData === null) {
        console.error("prevContentData is not an object:", prevContentData);
        return contentData; // or handle as needed
      }

      // Make sure projects_content is an array
      const unitsContent = prevContentData.projects_content || [];
      if (!Array.isArray(unitsContent)) {
        console.error("projects_content is not an array:", unitsContent);
        return contentData; // or handle as needed
      }

      // Clone the current unit to avoid direct mutation
      const updatedSections = [...unitsContent];

      // Ensure the data property exists and is an array
      if (!Array.isArray(updatedSections[index].data)) {
        console.error("data is not an array:", updatedSections[index].data);
        return contentData; // or handle as needed
      }

      // Add the new section (empty string) at the specified imageIndex
      updatedSections[index].data.splice(imageIndex + 1, 0, newSection);

      console.log("updated sec", updatedSections[index].data);

      // Return a new object with updated projects_content
      return {
        ...prevContentData,
        projects_content: updatedSections,
      };
    });
  };

  const handleClickOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };
  // Fetching content data (simulate fetching with hardcoded data)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Handle content change
  const handleContentChange = (index, value) => {
    const updatedContentData = { ...contentData };
    updatedContentData.projects_content[index].data = value;
    setContentData(updatedContentData);
  };

  const handlePdfUploadChange = async (index, event) => {
    const file = event.target.files[0];
    if (!file) return; // Prevent execution if no file is selected

    const formData = new FormData();
    formData.append("pdfFiles", file); // Append the file to FormData

    try {
      setpdfLoading(true); // Set loading state

      // Send a POST request with FormData
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify the content type
          },
        }
      );

      // Handle the response
      console.log("File upload response:", response.data.fileUrl);

      // Update contentData with the file URL
      const updatedContentData = { ...contentData };
      if (!updatedContentData.projects_content[index]) {
        updatedContentData.projects_content[index] = { type: "pdf", data: [] };
      }

      // Clear the 'data' array and set the uploaded file URL in 'pdfFiles'
      updatedContentData.projects_content[index].data = [response.data.fileUrl]; // Assuming response contains the URL
      // updatedContentData.projects_content[index].pdfFiles = file.name; // Store the file name or URL as needed

      setContentData(updatedContentData);
      console.log("contentdaat----------", contentData);
    } catch (err) {
      setError(err.message || "Failed to upload file");
    } finally {
      setpdfLoading(false); // Reset loading state
    }
  };

  const handleVideoUploadChange = async (index, event) => {
    const file = event.target.files[0];
    if (!file) return; // Prevent execution if no file is selected

    const formData = new FormData();
    formData.append("videoFile", file); // Append the file to FormData

    try {
      setVideoLoading(true); // Set loading state

      // Send a POST request with FormData
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Specify the content type
          },
        }
      );

      // Handle the response
      console.log("File upload response:", response.data.fileUrl);

      // Update contentData with the file URL
      const updatedContentData = { ...contentData };
      if (!updatedContentData.projects_content[index]) {
        updatedContentData.projects_content[index] = {
          type: "videoUpload",
          data: [],
        };
      }

      // Clear the 'data' array and set the uploaded file URL in 'pdfFiles'
      updatedContentData.projects_content[index].data = [response.data.fileUrl]; // Assuming response contains the URL
      // updatedContentData.projects_content[index].pdfFiles = file.name; // Store the file name or URL as needed

      setContentData(updatedContentData);
      console.log("contentdaat----------", contentData);
    } catch (err) {
      setError(err.message || "Failed to upload file");
    } finally {
      setVideoLoading(false); // Reset loading state
    }
  };

  const handleGalleryChange = async (index, imageIndex, event) => {
    const file = event.target.files[0]; // Only handle one file

    if (!file) return;

    const formData = new FormData();
    formData.append("imageFile", file); // Append the selected file

    try {
      setGalleryLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedContentData = { ...contentData };

      // Replace the specific image at imageIndex with the new URL
      updatedContentData.projects_content[index].data[imageIndex] =
        response.data.fileUrl; // Assuming response contains the URL

      setContentData(updatedContentData);
    } catch (err) {
      console.error(err.message || "Failed to upload file");
    } finally {
      setGalleryLoading(false);
    }
  };

  // Save updated content (mocked save functionality)
  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Simulate API call to save the edited content data
      console.log("Saving edited content:", contentData);

      // Perform your save request here, e.g., using axios
      // const response = await axios.put(
      //   `${process.env.REACT_APP_BACKEND}/unitupload/editunit/${contentData._id}`,
      //   contentData
      // );
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/projectupload/edit/${contentData.project_content_id}`,
        contentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      if (response.status) {
        // alert("Content saved successfully!");
        console.log("Content saved successfully:", contentData);
        // navigate(`/projectinterface/${contentData._id}`);
      }
    } catch (error) {
      console.error("Error saving content:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Container
        className="Nandhu"
        width="100%"
        sx={{
          width: "100%",
          padding: "0px !important",
        }}
      >
        <Box width={"100%"} sx={{ backgroundColor: "#9e503d", height: "auto" }}>
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: "23px", color: "#fff" }}
            >
              {/* <ArrowRightIcon sx={{ color: "#fff" }} /> HOME COURSE 1 GRADE 1
              VOL 1 <ArrowRightIcon sx={{ color: "#fff" }} /> MUSICAL
              PROGRAMMING: COMPOSING WITH MODI MODULES{" "}
              <ArrowRightIcon sx={{ color: "#fff" }} /> PERIOD 1 */}
              Edit Project
            </Typography>
          </Box>
        </Box>

        {loading ||
        !contentData ||
        !contentData.projects_content ||
        contentData.projects_content.length === 0 ? (
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <Box>
            {contentData.projects_content.map((item, index) => {
              switch (item.type) {
                case "projectHeading":
                  const isDataEditable1 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            Unit Heading
                          </Typography>

                          <Box>
                            <IconButton onClick={toggleEdit1} sx={{ ml: 2 }}>
                              {isDataEditable1 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            {/* <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton> */}
                          </Box>
                        </Box>

                        <Box width={"100%"}>
                          {isDataEditable1 ? (
                            <textarea
                              value={item.data}
                              onChange={(e) =>
                                handleContentChange(index, e.target.value)
                              }
                              style={{
                                width: "100%",
                                height: "100px",
                                resize: "none",
                              }} // Adjust height as needed
                            />
                          ) : (
                            <Typography
                              variant="h4"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Heading1":
                  const isDataEditable2 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            Heading 1
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable2 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable2 ? (
                            <ReactQuill
                              theme="snow"
                              value={item.data}
                              onChange={(value) =>
                                handleContentChange(index, value)
                              }
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Heading2":
                  const isDataEditable3 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            Heading 2
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable3 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable3 ? (
                            <ReactQuill
                              theme="snow"
                              value={item.data}
                              onChange={(value) =>
                                handleContentChange(index, value)
                              }
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Subheading1":
                  const isDataEditable4 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            SubHeading 1
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable4 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable4 ? (
                            <ReactQuill
                              theme="snow"
                              value={item.data}
                              onChange={(value) =>
                                handleContentChange(index, value)
                              }
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            {/* <MenuItem value="singleImage">Single Image</MenuItem> */}
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "Subheading2":
                  const isDataEditable5 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            Sub Heading 2
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable5 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable5 ? (
                            <ReactQuill
                              theme="snow"
                              value={item.data}
                              onChange={(value) =>
                                handleContentChange(index, value)
                              }
                            />
                          ) : (
                            <Typography
                              variant="h5"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                case "paragraph":
                  const isDataEditable6 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return (
                    <Container key={index} width={"100%"}>
                      <Box
                        key={index}
                        width={"100%"}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            Paragraph
                          </Typography>

                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable6 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box width={"100%"}>
                          {item.edit === 1 || isDataEditable6 ? (
                            <ReactQuill
                              theme="snow"
                              value={item.data}
                              onChange={(value) =>
                                handleContentChange(index, value)
                              }
                            />
                          ) : (
                            <Typography
                              variant="p"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );

                case "pdf":
                  const isDataEditable7 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return pdfLoading ? (
                    <Box sx={{ textAlign: "center", padding: "20px" }}>
                      <CircularProgress />
                      <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Loading...
                      </Typography>
                    </Box>
                  ) : (
                    <Container key={index} width={"100%"}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            mb: 2,
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            PDF Upload
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable7 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          {item.data.map((url, pdfIndex) => (
                            <Box
                              key={pdfIndex}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mb: 4,
                              }}
                            >
                              {error && (
                                <Typography
                                  color="error"
                                  variant="body2"
                                  sx={{ mb: 2 }}
                                >
                                  Error: {error.message}
                                </Typography>
                              )}
                              <Box
                                sx={{ maxHeight: "25%", width: "25%", p: 1 }}
                              >
                                <Document
                                  className="stypdf"
                                  file={url}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                  onLoadError={setError}
                                  loading={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        py: 4,
                                      }}
                                    >
                                      <CircularProgress />
                                    </Box>
                                  }
                                >
                                  <Box sx={{ mb: 2 }}>
                                    <Page
                                      pageNumber={1}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                      scale={0.8}
                                      width={
                                        document.querySelector("body")
                                          .offsetWidth - 0
                                      }
                                    />
                                  </Box>
                                </Document>
                              </Box>
                            </Box>
                          ))}
                          {isDataEditable7 ? (
                            <TextField
                              name="pdfFiles"
                              type="file"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                accept: "application/pdf",
                                multiple: false,
                              }}
                              onChange={(e) => handlePdfUploadChange(index, e)}
                              sx={{ marginBottom: 3 }}
                            />
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );

                case "videoUpload":
                  const isDataEditable8 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;
                  return videoLoading ? (
                    <Box sx={{ textAlign: "center", padding: "20px" }}>
                      <CircularProgress />
                      <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Loading...
                      </Typography>
                    </Box>
                  ) : (
                    <Container key={index} width={"100%"}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            mb: 2,
                          }}
                        >
                          <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                            Video Upload
                          </Typography>
                          <Box>
                            <IconButton
                              onClick={() => toggleEdit(index)}
                              sx={{ ml: 2 }}
                            >
                              {isDataEditable8 ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(index)} // Call the delete handler
                              sx={{ ml: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: 2,
                            width: "100%",
                          }}
                        >
                          <video
                            controls
                            style={{
                              width: "250px",
                              height: "250px",
                              borderRadius: "8px",
                              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                              marginRight: "16px", // Add margin between video and file upload
                            }}
                          >
                            <source src={item.data[0]} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          {isDataEditable8 ? (
                            <TextField
                              name="videoFile"
                              type="file"
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                accept: "video/mp4",
                                multiple: false,
                              }}
                              onChange={(e) =>
                                handleVideoUploadChange(index, e)
                              }
                              sx={{ marginBottom: 3, mt: 2 }}
                            />
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                    </Container>
                  );

                case "gallery": {
                  const isDataEditable9 =
                    item.data === "" ||
                    item.data.length === 0 ||
                    item.edit === 1;

                  return galleryLoading ? (
                    <Box sx={{ textAlign: "center", padding: "20px" }}>
                      <CircularProgress />
                      <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        Loading...
                      </Typography>
                    </Box>
                  ) : (
                    <Container width="100%">
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          maxWidth: "1200px",
                          margin: "0 auto",
                        }}
                      >
                        <Typography variant="h6" gutterBottom sx={{ mr: 2 }}>
                          Gallery
                        </Typography>
                        {item.data.map((url, imageIndex) => (
                          <Box
                            key={imageIndex}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginBottom: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mr: 2 }}
                              >
                                Gallery image {imageIndex + 1}
                              </Typography>

                              <Box>
                                <IconButton
                                  onClick={() => toggleEdit(index)}
                                  sx={{ ml: 2 }}
                                >
                                  {isDataEditable9 ? (
                                    <SaveIcon />
                                  ) : (
                                    <EditIcon />
                                  )}
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleGalleryDelete(index, imageIndex)
                                  }
                                  sx={{ ml: 2 }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginBottom: 2,
                                width: "100%",
                              }}
                            >
                              <img
                                src={url}
                                alt="Gallery"
                                onClick={() => handleClickOpen(url)}
                                style={{
                                  width: "250px",
                                  height: "250px",
                                  cursor: "pointer",
                                }}
                              />
                              {isDataEditable9 && (
                                <>
                                  <TextField
                                    type="file"
                                    variant="outlined"
                                    margin="normal"
                                    inputProps={{ accept: "image/*" }}
                                    onChange={(e) =>
                                      handleGalleryChange(index, imageIndex, e)
                                    }
                                    sx={{ marginTop: 1, width: "100%" }}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(event) =>
                                      handleImageContentTypeChange(
                                        index,
                                        imageIndex,
                                        event
                                      )
                                    }
                                    sx={{ marginTop: 1 }}
                                  >
                                    Add Image
                                  </Button>
                                </>
                              )}
                            </Box>
                          </Box>
                        ))}

                        <Dialog open={open} onClose={handleClose} maxWidth="lg">
                          <DialogContent>
                            <img
                              src={selectedImage}
                              alt="Selected"
                              style={{ width: "250px", height: "250px" }}
                            />
                          </DialogContent>
                        </Dialog>
                      </Box>
                      {!hideFormControl && (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="normal"
                        >
                          <InputLabel>Choose content</InputLabel>
                          <Select
                            label="Choose content"
                            value={selectedContentType} // Ensure this is defined
                            onChange={(event) =>
                              handleContentTypeChange(event, index)
                            }
                            sx={{ marginBottom: 3 }}
                          >
                            <MenuItem value="Heading1">Heading 1</MenuItem>
                            <MenuItem value="Heading2">Heading 2</MenuItem>
                            <MenuItem value="Subheading1">
                              Sub Heading 1
                            </MenuItem>
                            <MenuItem value="Subheading2">
                              Sub Heading 2
                            </MenuItem>
                            <MenuItem value="paragraph">Paragraph</MenuItem>
                            <MenuItem value="pdf">PDF Upload</MenuItem>
                            <MenuItem value="gallery">Gallery / Image</MenuItem>
                            <MenuItem value="videoUpload">
                              Video Upload
                            </MenuItem>
                            <MenuItem value="videoUrl">Video URL</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Container>
                  );
                }

                default:
                  return null;
              }
            })}
          </Box>
        )}

        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              isSaving ||
              isEditing ||
              pdfLoading ||
              galleryLoading ||
              videoLoading
            } // Disable if either isSaving or pdfLoading is true
          >
            {isSaving ? "Saving..." : "Save Changes"}
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default EditUnit;
