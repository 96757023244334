import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Switch,
  Grid,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import ReactQuill from "react-quill";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import { useLocation } from "react-router-dom";
const Assessment = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const [assessment_name, setAssessment_name] = useState("");
  const [expiretimee, setExpiretime] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [statusToggle, setStatusToggle] = useState("0");
  const [assessmentId, setAssessmentId] = useState("")
  const [loading, setLoading] = useState(false); // New loading state
  const [status, setStatus] = useState(null); // null until fetched
  const { unitId, grade_id,grade_namee,grade_division_id, coursenamee, chapterindexx, chaptername, unitnamee } = location.state || {};
  const token = localStorage.getItem("token_key");
  useEffect(() => {
    fetchAssessmentQuestions();
  }, [statusToggle]);

  const fetchAssessmentQuestions = async () => {
    try {
      const assessmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentname/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      // Extracting the data from the response
      // const { status, message, data } = assessmentResponse.data;
      const { message, data } = assessmentResponse.data;

      // Check if data is not empty
      if (data.length > 0) {
        const assessmentDetails = data[0]; // Accessing the first element in the data array
        console.log("Assessment_deatailss:",assessmentDetails);
        
        // Now you can access the properties directly
        const assessmentTitle = assessmentDetails.assessment_title;
        const totalScore = assessmentDetails.total_score;
        console.log("Assessment Title:", assessmentTitle);
        console.log("Total Score:", totalScore);
        setAssessment_name(assessmentTitle);
        const hours = Math.floor(assessmentDetails.expire_time / 3600);
        const minutes = Math.floor(
          (assessmentDetails.expire_time % 3600) / 60
        );
        const seconds = assessmentDetails.expire_time % 60;
        const setTime = `${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
        setExpiretime(setTime);
        setStatusToggle(assessmentDetails.status);

        
        console.log("assessment status:",assessmentDetails.status);
        setAssessmentId(assessmentDetails._id)
        
      } else {
        console.log("No assessment data found.");
      }

      console.log("Unit id:", id);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment/byunitId/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const assessmentData = response.data.data || [];
      setAssessmentQuestions(
        Array.isArray(assessmentData) ? assessmentData : []
      );
    } catch (error) {
      console.error("Error fetching assessment questions:", error);
    }

  };

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

  const handleSubmit = async () => {
    const formattedAnswers = assessmentQuestions.map((question, index) => ({
      [index]: selectedAnswers[index] || "", // Only the selected answer or an empty string
    }));

    let totalPoints = 0;

    assessmentQuestions.forEach((question, index) => {
      const selectedAnswer = selectedAnswers[index];

      // Calculate points only for "yes/no" and "mcq" types
      if (
        question.question_type === "mcq" ||
        question.question_type === "yes/no"
      ) {
        if (selectedAnswer === question.answer) {
          totalPoints += question.points; // Add points if the answer is correct
        }
      }
      // For "descriptive" questions, points are always 0, so no action needed.
    });

    console.log("Formatted Answers:", formattedAnswers);
    console.log("Total Points:", totalPoints);
  };

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        // Check if assessmentId and unitId are available
        if (!assessmentId || !unitId) {
          console.log("Missing unitId or assessmentId, skipping API call.");
          return;
        }
  
        console.log("Assessment ID being sent:", assessmentId);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/get-enabled-status/${unitId}`,
          { 
            params: { assessmentId: assessmentId }  // Pass assessmentId in the query parameters
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${getToken()}`,
            }
          }
        );
        setStatus(response.data.status);
      } catch (error) {
        console.error('Error fetching status:', error);
      } finally {
        setLoading(false);

      }
    };
  
    fetchStatus();
  }, [unitId, assessmentId]);  // Added assessmentId to dependency array
  

  const toggleStatus = async () => {
    try {
      const newStatus = status === 1 ? 0 : 1; // Toggle between 0 and 1
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/assessment/update-enabled-status/${unitId}`, 
        { 
          status: newStatus, 
          assessmentId: assessmentId,
          grade_division_id:grade_division_id,
          grade_idf:grade_id  // Pass assessmentId in the body
        },
        {
          headers: {Authorization: `Bearer ${token}`},  // Authorization header with Bearer token
        }
      );
      
      
      setStatus(newStatus); // Update local status
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  if (loading) return <p>Loading...</p>;



  return (
    <Container maxWidth="lg">
      {/* Breadcrumb Section */}
      <Box sx={{ backgroundColor: "#9e503d", height: "50px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "50px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: "18px", color: "#fff !important" }}
            >
              {grade_namee}
              <ArrowRightIcon sx={{color:'#fff'}}/>
              {coursenamee}
              <ArrowRightIcon sx={{color:'#fff'}}/>
              Chapter {chapterindexx} :{chaptername}: {unitnamee}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f1f1",
          height: "100px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          marginBottom: "30px",
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Typography variant="h3" gutterBottom>
            {assessment_name}
          </Typography>
        </Container>
      </Box>
  <Box
    sx={{
      backgroundColor: "#f1f1f1", // check the color or adjust it
      height: "auto",
      display: "flex",
      alignItems: "center",
      paddingLeft: "20px", // add spacing if needed
      marginBottom: "20px", // adjust for visual spacing
      justifyContent: "space-between",
    }}
  >
    <p>Expire Time: {expiretimee}</p>
    <div>
  <button
    onClick={toggleStatus}
    style={{
      backgroundColor: 'grey',  // button in grey regardless of status
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginBottom: '10px',
    }}
  >
    {status === 1 ? 'Inactivate' : 'Activate'}
  </button>

  <p
    style={{
      color: status === 1 ? 'green' : 'red',  // Green if active, red if inactive
      fontSize: '18px',
      fontWeight: 'bold',
      marginTop: '10px',
    }}
  >
    Current Status: {status === 1 ? 'Active' : 'Inactive'}
  </p>
</div>
  </Box>

      {/* MCQ Section */}
      {Array.isArray(assessmentQuestions) && assessmentQuestions.length > 0 ? (
        assessmentQuestions.map((q, index) => (
          <Box key={index} mb={2}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              {`${index + 1}. ${q.question_text}`}
            </Typography>
            {q.question_type === "mcq" && (
              <div name={`question-${index}`}>
                {q.options.map((option, i) => (
                  <div
                    key={i}
                    style={{
                      margin: "8px 0",
                      cursor: "pointer",
                      padding: "8px",
                      backgroundColor:
                        q.answer === option ? "#21932f" : "#f0f0f0",
                      borderRadius: "4px",
                      color: q.answer === option ? "#fff" : "#000",
                    }}
                    onClick={() => handleAnswerChange(index, option)}
                  >
                    {String.fromCharCode(65 + i)}. {option}{" "}
                    {/* Display letters A, B, C, D followed by the option */}
                  </div>
                ))}
              </div>
            )}

            {q.question_type === "yes/no" && (
              <div
                style={{
                  margin: "8px 0",
                  padding: "8px",
                  // backgroundColor: q.answer === "Yes" ? "#21932f" : q.answer === "No" ? "#d32f2f" : "",
                  borderRadius: "4px",
                }}
              >
                Answer: {q.answer} {/* Display the answer directly */}
              </div>
            )}

            {q.question_type === "descriptive" && (
              <textarea
                style={{
                  width: "100%",
                  border: "1px solid #ccc", // Light gray border
                  borderRadius: "4px", // Rounded corners
                  padding: "10px", // Padding for inner spacing
                  fontSize: "16px", // Font size for readability
                  minHeight: "100px", // Minimum height for the textarea
                  resize: "vertical", // Allow vertical resizing only
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                  outline: "none", // Remove default outline
                  transition: "border-color 0.2s", // Smooth transition for border color
                }}
                value={q.answer} // Use q.answer if no answer has been selected
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                onFocus={(e) => (e.target.style.borderColor = "#33c7ff")} // Change border color on focus
                onBlur={(e) => (e.target.style.borderColor = "#ccc")} // Reset border color on blur
              />
            )}
          </Box>
        ))
      ) : (
        <Typography>No questions available.</Typography>
      )}

      {/* Submit Button */}
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Answers
        </Button>
      </Box>
    </Container>
  );
};

export default Assessment;
