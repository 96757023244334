import React from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook"; // Book icon for Courses, Chapters, Units
import QuizIcon from "@mui/icons-material/Quiz"; // Icon for Quizzes
import ProjectIcon from "@mui/icons-material/Build"; // Icon for Projects
import CommentIcon from "@mui/icons-material/Comment";
 
// Dashboard data for dynamic card generation
const dashboardData = [
  {
    title: "NO OF SCHOOLS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
  {
    title: "NO OF TEACHERS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
  {
    title: "NO OF STUDENTS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
  {
    title: "PROJECTS SUBMITTED",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
  {
    title: "NO OF ASSESSMENTS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
  {
    title: "NO OF QUIZZES",
    number1: 40,
    number2: 3225,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
  {
    title: "NO OF TICKETS",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
  {
    title: "TASKS SUBMITTED",
    number1: 40,
    number2: 36,
    color1: "#fde0a9",
    color2: "#e4443f",
  },
];
 
// Data for circular progress cards
const progressData = [
  { value: 33, label: "> COURSES", icon: MenuBookIcon },
  { value: 33, label: "> CHAPTERS", icon: MenuBookIcon },
  { value: 33, label: "> UNITS", icon: MenuBookIcon },
  { value: 33, label: "> QUIZZES", icon: QuizIcon },
  { value: 4, label: "> PROJECTS", icon: ProjectIcon },
  { value: 13, label: "> COMMENTS", icon: CommentIcon },
];
const circleData = [
  { title: "> Score", value: 67 },
  { title: "> Teacher Rating", value: 5 },
  { title: "> Quiz", value: 73 },
  { title: "> Class Average", value: 67 },
  { title: "> Engagement", value: 44 },
  { title: "> Discussion", value: 56 },
  { title: "> Consistency", value: 78 },
  { title: "> Critical Thinking", value: 34 },
  { title: "> Creative Thinking", value: 52 },
];
 
const TeacherDashboard = () => {
  return (
    <Container maxWidth="xl" sx={{ paddingY: 4 }}>
      <Grid container spacing={2}>
        {/* Dynamically generate DashboardCards */}
        {dashboardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <DashboardCard {...card} />
          </Grid>
        ))}
 
        {/* Ratings Section */}
        <Grid container item xs={12} spacing={2}>
          {["SCHOOLS", "TEACHERS", "STUDENTS", "PROJECTS"].map(
            (type, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <RatingCard
                  title={`${type} RATING`}
                  excellent={40}
                  good={22}
                  average={12}
                  bad={6}
                  barWidths={[40, 60, 60, 25]}
                />
              </Grid>
            )
          )}
        </Grid>
 
        {/* Circular Progress Cards */}
        <Grid
          container
          item
          xs={10}
          md={10}
          spacing={0}
          ml={9}
          mt={5}
          // justifyContent="space-around" // Align circles in a single row
          sx={{ paddingTop: 2 }}
        >
          {progressData.map((item, index) => (
            <Grid item key={index}>
              <CircularCard
                score={item.value}
                total={100}
                title={item.label}
                IconComponent={item.icon} // Pass icon component here
              />
            </Grid>
          ))}
        </Grid>
 
        {/* Circle Cards Section */}
        <Grid
          container
          item
          xs={12}
          spacing={2}
          justifyContent="center"
          alignItems="center" // Align arrows with the bottom of the circles
          sx={{ paddingTop: 2 }}
        >
          <Grid item>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fde0a9",
                borderRadius: "20%",
                cursor: "pointer",
                boxShadow: 3,
              }}
            >
              <Typography variant="h6">{"<"}</Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {circleData.map((circle, index) => (
                <Grid item key={index}>
                  <CircleCard title={circle.title} value={circle.value} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fde0a9",
                borderRadius: "20%",
                cursor: "pointer",
                boxShadow: 3,
              }}
            >
              <Typography variant="h6">{">"}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
 
// DashboardCard Component
// DashboardCard Component with responsive styles
const DashboardCard = ({ title, number1, number2, color1, color2 }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: "8px",
        boxShadow: 3,
        backgroundColor: color1,
        padding: { xs: "12px", sm: "16px", md: "20px" }, // Responsive padding
      }}
    >
      <Typography
        variant="h5"
        align="center"
        fontWeight="bold"
        sx={{
          marginBottom: { xs: 1, sm: 2 }, // Responsive margin bottom
          // wordBreak: "break-word",
          fontSize: { xs: "16px", sm: "20px", md: "24px" }, // Responsive font size for title
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        {[number1, number2].map((num, idx) => (
          <Grid item xs={5} key={idx}>
            <Box
              sx={{
                backgroundColor: idx === 0 ? color2 : "#000", // Left card stays the same, right card gets black background
                color: idx === 0 ? "#fff" : "rgba(255, 255, 255, 0.7)", // Adjust text color based on background
                textAlign: "center",
                padding: { xs: "10px", sm: "16px", md: "20px" }, // Responsive padding
                borderRadius: "10px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontSize: {  sm: "17px", md: "19px", lg:"25px" }, // Responsive font size for numbers
                  whiteSpace: "nowrap", // Prevent text from breaking into a new line
                  overflow: "hidden", // Hide overflow if the text is too long
                  textOverflow: "ellipsis", // Add ellipsis if the text is too long
                }}
              >
                {num}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
 
// Updated RatingCard with customizable bar widths
const RatingCard = ({ title, excellent, good, average, bad, barWidths }) => {
  const ratings = [
    {
      label: "EXCELLENT",
      value: excellent,
      color: "#f05622",
      width: barWidths[1],
    },
    { label: "GOOD", value: good, color: "#0087c0", width: barWidths[1] },
    { label: "AVERAGE", value: average, color: "#9b2e9b", width: barWidths[2] },
    { label: "BAD", value: bad, color: "#323232", width: barWidths[3] },
  ];
 
  return (
    <Card sx={{ borderRadius: "16px", boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={2}>
          {ratings.map((rating, index) => (
            <Grid item xs={12} key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {/* Bar (Label) on the Left */}
                <Box
                  sx={{
                    backgroundColor: rating.color,
                    color: "#fff",
                    padding: "8px 16px",
                    width: `${rating.width}%`, // Customize the width with percentage
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {rating.label}
                  </Typography>
                </Box>
 
                {/* Set of Numbers on the Right */}
                <Box display="flex" alignItems="center">
                  {/* Left number set */}
                  <Box
                    sx={{
                      backgroundColor: rating.color, // Same color as the rating bar
                      padding: "8px",
                      width: "60px",
                      color: "#fff",
                      textAlign: "center",
                      marginRight: "8px",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      {rating.value}
                    </Typography>
                  </Box>
 
                  {/* Right number set */}
                  <Box
                    sx={{
                      backgroundColor: rating.color, // Same color as the rating bar
                      padding: "8px",
                      width: "60px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      {rating.value}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
 
const CircularCard = ({ score, title, IconComponent }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle width
        height: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle height
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle width
          height: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle height
          borderRadius: "50%",
          backgroundColor: "black",
          border: "8px solid #ac3a38", // Adjusted border size for consistency
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {IconComponent && (
          <IconComponent
            sx={{
              fontSize: { xs: "20px", sm: "30px", md: "35px" }, // Responsive icon size
              position: "absolute",
              color: "white",
              bottom: { xs: "5px", sm: "10px", md: "15px" }, // Adjust bottom position based on screen size
            }}
          />
        )}
        <Typography
          variant="h5"
          sx={{
            color: "white",
            fontSize: { xs: "18px", sm: "22px", md: "28px" }, // Responsive font size for the score
            zIndex: 1,
            position: "absolute",
            top: { xs: "5px", sm: "8px", md: "10px" }, // Adjust position based on screen size
          }}
        >
          {score}
        </Typography>
      </Box>
 
      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
          fontSize: { xs: "12px", sm: "14px" }, // Responsive title font size
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
 
const CircleCard = ({ title, value }) => {
  return (
    <Box
      sx={{
        width: { xs: "90px", sm: "110px", md: "120px" }, // Responsive width
        height: { xs: "90px", sm: "110px", md: "120px" }, // Responsive height
        position: "relative",
        marginX: { xs: "4px", sm: "6px", md: "8px" }, // Responsive margin
      }}
    >
      {/* Full circle */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: "10px solid #fde0a9", // Circle stroke color
          position: "relative",
        }}
      >
        {/* Top half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#000", // Top half background
            borderTopLeftRadius: "60px",
            borderTopRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
          }}
        >
          <Typography
            variant="h3"
            color="#fff"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "28px" }, // Responsive font size for the value
            }}
          >
            {value}
          </Typography>
        </Box>
 
        {/* Bottom half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#ac3a38", // Bottom half background
            borderBottomLeftRadius: "60px",
            borderBottomRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Typography
            variant="h4"
            color="#fff"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "28px" }, // Responsive font size for bottom number
            }}
          >
            100
          </Typography>
        </Box>
      </Box>
 
      {/* Title below the circle */}
      <Typography
        variant="body2"
        align="center"
        sx={{
          position: "absolute",
          bottom: "-30px",
          width: "100%",
          fontWeight: "bold",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: { xs: "12px", sm: "14px" }, // Responsive title font size
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
 
export default TeacherDashboard;