import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  CircularProgress,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getToken } from "../../../utils/AuthUtils";
 
const CourseListMain = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedCourseId, setExpandedCourseId] = useState(null);
 
  useEffect(() => {  
    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses/assignedcoursestosc`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
         
          // Log the result
          console.log("Fetched courses:", response.data.data);
         
          setCourses(response.data.data);
        }
      } catch (err) {
        console.error("Error fetching courses:", err);
      } finally {
        setIsLoading(false);
      }
    };  
   
    fetchCourses();
  }, []);
 
  const toggleExpand = (id) => {
    setExpandedCourseId(expandedCourseId === id ? null : id);
  };
 
  return (
    <Container maxWidth="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} padding={1} sx={{ backgroundColor: "#e8e8e8" }}>
        <Typography variant="h4" gutterBottom>
          Courses
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" padding={2}>  
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {courses.length > 0 ? (
              courses.map((group) => (
                <Accordion key={group.grade_id} sx={{ backgroundColor: "#e0e0e0" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => toggleExpand(group.grade_id)}>
                    <Typography variant="h6" sx={{ color: "#969292 !important" }}>
                    {`Grade Name: ${group.grade_name}`} {/* Displaying the grade_name instead of grade_id */}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexDirection="column" gap={2}>
                      {group.courses.map((course) => (
                        <Grid container spacing={2} key={course._id}>
                          <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
                            {course.course_image ? (
                              <img
                                src={course.course_image}
                                alt="Course Image"
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <Typography>No Photo</Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Typography variant="h5" sx={{ fontWeight: "bold", ml: 1 }}>
                              {course.courses_name}
                            </Typography>
                            <Typography sx={{ ml: 1, mt: 1 }}>
                              {course.course_details} {/* Assuming you have this field */}
                            </Typography>
                            <Box display="flex" alignItems="center" mt={2} sx={{ ml: 1 }}>
                              <Typography variant="subtitle1">Department:</Typography>
                              <Typography ml={1}>{course.department_name}</Typography> {/* Assuming you have this field */}
                            </Box>
                            <Box display="flex" alignItems="center" mt={1} sx={{ ml: 1 }}>
                              <Typography variant="subtitle1">Date Created:</Typography>
                                  <Typography ml={1}>
                                     {course.date_created
                                        ? new Date(course.date_created).toLocaleDateString()
                                     : "Date not available"}
                               </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                      {/* <Box display="flex" justifyContent={{ xs: "center", md: "flex-end" }} mt={2}>
                        <Button variant="contained">View Chapters</Button>
                      </Box> */}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography align="center">No courses found</Typography>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};
 
export default CourseListMain;