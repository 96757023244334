import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, TextField, MenuItem, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
 
const AddCoursePage = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [schoolSubcategories, setSchoolSubcategories] = useState([]);
  const [departments, setDepartments] = useState([]); // New state for departments
  const [course, setCourse] = useState({
    courses_name: "",
    course_details: "",
    no_of_chapters: 0,
    status: 1,
    school_subcategory_id: "",
    course_subcategory_id: "",
    department_id: "", // New field for department
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState(null)
  const [courseImage, setCourseImage] = useState(null)
  const navigate = useNavigate();

  const handleImageChange = (e, type) => {
    if (type === 'thumbnail') {
      setThumbnailImage(e.target.files[0]);  // Set the thumbnail image file
      console.log(e.target.files[0]);
      
    } else if (type === 'course') {
      setCourseImage(e.target.files[0]);  // Set the course image file
      console.log(e.target.files[0]);
    }
  };

 
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category`,
          {
            headers: {
              Authorization:`Bearer ${getToken()}`
          }
        }
        );
        setCategories(response.data.data);
        console.log("inside categories");
        
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
 
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`,
          {
            headers: {
              Authorization:`Bearer ${getToken()}`
              }
            }
        );
        setSubcategories(response.data.data);
        console.log(response);
        
        console.log("inside course sub categories");
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
 
    const fetchSchoolSubcategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`,
          {
            headers: {
              Authorization:`Bearer ${getToken()}`
            }
          }
        );
        setSchoolSubcategories(response.data.data);
        console.log(response);
        console.log("inside  fetch School Subcategories");

        
        
      } catch (error) {
        console.error("Error fetching school subcategories:", error);
      }
    };
 
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`,
          {
            headers: {
              Authorization:`Bearer ${getToken()}`
            }
          }
        );
        setDepartments(response.data.data);
        console.log("inside  fetch Departments");

        
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
 
    // Fetch all required data
    Promise.all([fetchCategories(), fetchSubcategories(), fetchSchoolSubcategories(), fetchDepartments()])
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCourse((prev) => ({ ...prev, [name]: value }));
  };
 
  const handleSave = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    const formData = new FormData();
    formData.append('courses_name', course.courses_name);
    formData.append('course_details', course.course_details);
    formData.append('no_of_chapters', course.no_of_chapters);
    formData.append('status', course.status);
    formData.append('school_subcategory_id', course.school_subcategory_id);
    formData.append('course_subcategory_id', course.course_subcategory_id);
    formData.append('department_id', course.department_id);

    if (thumbnailImage) {
      formData.append('course_thumbnail', thumbnailImage);
    }

    if (courseImage) {
      formData.append('course_image', courseImage);
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/courses`, formData,{
        headers:{
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${getToken()}`
        }
      });
      navigate("/courses_list");
    } catch (error) {
      console.error("Error saving course:", error);
    } finally {
      setIsSaving(false);
    }
  };
 
  return (
    <Box padding={2}>
      <Typography variant="h4" gutterBottom>
        Add Course
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="Course Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="courses_name"
            value={course.courses_name}
            onChange={handleInputChange}
          />
          <TextField
            label="Course Details"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course_details"
            value={course.course_details}
            onChange={handleInputChange}
          />
          {/* <TextField
            label="Course Thumbnail URL"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course_thumbnail"
            value={course.course_thumbnail}
            onChange={handleInputChange}
          /> */}
          <Grid container spacing={2}>
      {/* Upload Course Thumbnail Image */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Course Thumbnail Image
        </Typography>
        <Button variant="contained" component="label">
          Upload Course Thumbnail Image
          <input
            type="file"
            hidden
            onChange={(e) => handleImageChange(e, 'thumbnail')} // Pass 'thumbnail'
          />
        </Button>
        {thumbnailImage && (
          <Typography>{thumbnailImage.name}</Typography>
        )}
      </Grid>

      {/* Upload Course Image */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Course Image
        </Typography>
        <Button variant="contained" component="label">
          Upload Course Image
          <input
            type="file"
            hidden
            onChange={(e) => handleImageChange(e, 'course')} // Pass 'course'
          />
        </Button>
        {courseImage && (
          <Typography>{courseImage.name}</Typography>
        )}
      </Grid>
    </Grid>
          {/* <TextField
            label="Course Image URL"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course_image"
            value={course.course_image}
            onChange={handleInputChange}
          /> */}
          <TextField
            label="Number of Chapters"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            name="no_of_chapters"
            value={course.no_of_chapters}
            onChange={handleInputChange}
          />
 
          {/* Department Dropdown */}
          <TextField
            label="Department"
            variant="outlined"
            fullWidth
            margin="normal"
            name="department_id"
            select
            value={course.department_id}
            onChange={handleInputChange}
          >
            {departments.map((department) => (
              <MenuItem key={department._id} value={department._id}>
                {department.department_name}
              </MenuItem>
            ))}
          </TextField>
 
          {/* School Subcategory Dropdown */}
          <TextField
            label="School Subcategory"
            variant="outlined"
            fullWidth
            margin="normal"
            name="school_subcategory_id"
            select
            value={course.school_subcategory_id}
            onChange={handleInputChange}
          >
            {schoolSubcategories.map((subcategory) => (
              <MenuItem key={subcategory._id} value={subcategory._id}>
                {subcategory.SubCategory_name}
              </MenuItem>
            ))}
          </TextField>
 
          {/* Existing Subcategory Dropdown */}
          <TextField
            label="Course Subcategory"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course_subcategory_id"
            select
            value={course.course_subcategory_id}
            onChange={handleInputChange}
          >
            {subcategories.map((subcategory) => (
              <MenuItem key={subcategory._id} value={subcategory._id}>
                {subcategory.courses_sub_category_name}
              </MenuItem>
            ))}
          </TextField>
 
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isSaving}
            sx={{ mt: 2 }}
          >
            {isSaving ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </Box>
      )}
    </Box>
  );
};
 
export default AddCoursePage;