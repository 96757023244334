import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";
import axios from "axios";
 
const UnitAssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [forFilter, setForFilter] = useState({
    course: "",
    grade: "",
    gradeDivision: "",
    studentId:""
  });
 
  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);
 
  useEffect(() => {
    fetchUnitAssessment();
  }, [page, rowsPerPage, searchTerm]);
 
  const fetchUnitAssessment = async () => {
    try {
      const token = getToken();
      if(!token){
        console.log("Unauthorized user");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/assessment/list?page=${page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        }
      );
      const data = await response.json();
      if (data?.data) {
        const assessmentWithSerial = data.data.map((assessment, index) => ({
          ...assessment,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !assessment.status, // Add this line
        }));
        setAssessments(assessmentWithSerial);
        setSerialNumbers(assessmentWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    // if(!forFilter.course){
    //   return;
    // }
    const fetchcourse = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/coursebystudent`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const coursesData = response.data.data;
        setCourses(coursesData);
        console.log("coursesss: ",coursesData);
       
        // setAssessments(coursesData);
 
        console.log("coursesData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchcourse();
  }, []);
 
  useEffect(() => {
    if(!forFilter.course){
      return;
    }
    const fetchgrade = async () => {
      try {
        const token = getToken();
        console.log("course Idddddd: ",forFilter.course);
       
        if (!token) {
          console.log("Access Denied");
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assessment/assessmentbycourse/${forFilter.course}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const gardesData = response.data.data;
        setAssessments(gardesData);
        console.log("gardesData: ", response.data.data);
      } catch (error) {
        console.error("Error fetching gardesData:", error);
      }
    };
    fetchgrade();
  }, [forFilter.course]);
 
  const deleteTeacher = async (id) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${id}`, {
        method: "DELETE",
        headers: {
          Authorization:`Bearer ${getToken()}`
        }
      });
      setSnackbarMessage("Teacher deleted successfully");
      setOpenSnackbar(true);
      fetchUnitAssessment();
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };
 
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
 
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };
 
  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/status_teacher`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchUnitAssessment(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };
 
  const moveToAddTeacher =() =>{
    navigate("/add-teacher")
  }
 
  return (
    <Box>
      <h2>Unit Assessment List</h2>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" padding={3} gap={1} >
          <TextField
            label="Search"
            variant="outlined"
            size="Large"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "350px", }}
          />
 
<FormControl margin="normal" sx={{ minWidth: 350 }}>
              <InputLabel>Select Course</InputLabel>
              <Select
                value={forFilter.course}
                onChange={(e) =>
                    {
                      // forFilter.grade([]);
                      setAssessments([]);
 
                      setForFilter({
                      course: e.target.value
                    });}}
                label="Grade"
                aria-required="true"
              >
                <MenuItem value="">Select All</MenuItem>
                {courses.map((item) => (
                  <MenuItem key={item._id} value={item.course_id._id}>
                    {item.course_id ? item.course_id.courses_name : "Unknown Grade"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
         
          <Box flexGrow={1} /> {/* This will push the button to the right */}
          {/* <Button
            component={Link}
            // to="/school/assigned-course"
            onClick={moveToAddTeacher}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ height: "50px", backgroundColor: "#e0e0e0" }}
          >
          Create
          </Button> */}
        </Box>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
          <TableRow>
              <TableCell>
                <b>Serial</b>
              </TableCell>
              {/* <TableCell>
                <b>Student Name</b>
              </TableCell> */}
              {/* <TableCell>
                <b>Grade</b>
              </TableCell> */}
              <TableCell>
                <b>Chapter</b>
              </TableCell>
              <TableCell>
                <b>Unit</b>
              </TableCell>
              <TableCell>
                <b>Assessment Title</b>
              </TableCell>
              <TableCell>
                <b>Other Score</b>
              </TableCell>
              <TableCell>
                <b>Descriptive Score</b>
              </TableCell>
              <TableCell>
                <b>Total Score</b>
              </TableCell>
              <TableCell>
                <b>Class Average</b>
              </TableCell>
              {/* <TableCell>
                <b>Participation</b>
              </TableCell>
              <TableCell>
                <b>Comment</b>
              </TableCell> */}
              <TableCell>
                <b>Actions</b>
              </TableCell>
              <TableCell>
                <b>Date</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {assessments.length > 0 ? (
  assessments.map((assessment, index) => {
    // Ensure assessment.answers is an array before proceeding
    const descriptiveScore = assessment.answers && Array.isArray(assessment.answers)
      ? assessment.answers
          .filter(answer => answer.question_id && answer.question_id.question_type === "descriptive")
          .reduce((acc, answer) => acc + (answer.points || 0), 0) // Ensure points exist
      : 0;
 
      const notdescriptiveScore = assessment.answers && Array.isArray(assessment.answers)
      ? assessment.answers
          .filter(answer => answer.question_id && answer.question_id.question_type != "descriptive")
          .reduce((acc, answer) => acc + (answer.points || 0), 0) // Ensure points exist
      : 0;
 
    return (
      <TableRow key={assessment._id}>
        <TableCell>{index + 1}</TableCell>
        {/* <TableCell>{assessment.student_id.name}</TableCell> */}
        {/* <TableCell>{assessment.student_id.grade_division}</TableCell> */}
        <TableCell>{assessment.assessment_id.unit.chapter_id.chapter_name}</TableCell>
        <TableCell>{assessment.assessment_id.unit.unit_name}</TableCell>
        <TableCell>{assessment.assessment_id.assessment_title}</TableCell>
        <TableCell>{notdescriptiveScore}</TableCell>
        <TableCell>{descriptiveScore}</TableCell> {/* Display the descriptive score */}
        <TableCell>{assessment.score}</TableCell>
        <TableCell>{assessment.score}/{assessment.averageScore}</TableCell>
        {/* <TableCell>{assessment.teacher_id}</TableCell>
        <TableCell>{assessment.teacher_id}</TableCell> */}
        <TableCell>
          <IconButton
            color="secondary"
            onClick={() => navigate(`/student/unit-assessment-List/${assessment.assessment_id.unit._id}/${assessment._id}`)}
 
          >
            <VisibilityIcon />
          </IconButton>
        </TableCell>
        <TableCell>{new Date(assessment.createdAt).toLocaleDateString('en-CA')}</TableCell>
      </TableRow>
    );
  })
) : (
  <TableRow>
    <TableCell colSpan={9} align="center">No Assessments available</TableCell>
  </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
 
export default UnitAssessmentList;