import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";
 
const AssignCourse = () => {
  const navigate = useNavigate();
  const [newTeacher, setNewTeacher] = useState({
    teacherCategory: "",
    teacherSubCategory: "",
    teacher: "",
    // department: "",
    grade_id: "", // Initialize grade_id state
    grade_division_id: [],
    course_id: "",
  });
 
  const [error, setError] = useState(""); // State for error handling
  const [teacherCategories, setTeacherCategories] = useState([]);
  const [teacherSubCategories, setTeacherSubCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [teacherFilter, setTeacherFilter] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [grades, setGrades] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [courses, setCourses] = useState([]);
  const [alreadySelectedTeacherData, setAlreadySelectedTeacherData] = useState([]); // initialize as an empty array
  const [alreadySelectedGradeDivisionCourse, setAlreadySelectedGradeDivisionCourse] = useState([]);
 
  // Fetch teacher categories
  useEffect(() => {
    const fetchTeacherCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teachercategory`,
          {
            headers:{
              Authorization: `Bearer ${getToken()}`
            }
          }
        );
        setTeacherCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher categories:", error);
      }
    };
    fetchTeacherCategories();
  }, []);

  // fetch all courses from assign courses to school
  useEffect(() => {
    fetchAllCoursesbyschool();
  }, []);

  const fetchAllCoursesbyschool = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assign_course/coursesBySchool`,
        {
          headers:{
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      setCourses(response.data.data);
      console.log(response.data);
      
    } catch (error) {
      console.error("Error fetching all assigned courses to school:", error);
    }
  };
 
  // Fetch teacher sub-categories
  useEffect(() => {
    const fetchTeacherSubCategories = async () => {
      if (!newTeacher.teacherCategory) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/teacher/sub/${newTeacher.teacherCategory}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            }
          }
        );
        setTeacherSubCategories(response.data.data);
        setFilteredSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching teacher sub-categories:", error);
      }
    };
    fetchTeacherSubCategories();
  }, [newTeacher.teacherCategory]);
 
  // Filter sub-categories based on selected teacher category
  useEffect(() => {
    if (newTeacher.teacherCategory) {
      setFilteredSubcategories(
        teacherSubCategories.filter(
          (sub) => sub.teacher_category_id === newTeacher.teacherCategory
        )
      );
    }
  }, [teacherSubCategories, newTeacher.teacherCategory]);
 
  // Fetch teachers based on sub-category
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = getToken();
        if (!token) {
          console.log("Unauthorized user");
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/allteachers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const teacherData = response.data.data;
        const filteredTeachers = teacherData.filter(
          (item) => item.teacher_subCategoryId === newTeacher.teacherSubCategory
        );
        setTeacherFilter(filteredTeachers);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }
    };
    fetchTeachers();
  }, [newTeacher.teacherSubCategory]);
 
 
 
 
  useEffect(() => {
    const fetchAllgrades = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/grade/assignedgrade`,
        {
          headers:{
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
 
      const data = await response.json();
      if (response.ok) {
        setAllGrades(data.data); // Set grades from the response
      }
    };
    fetchAllgrades();
  }, []);
  // Fetch grades based on selected grade
  useEffect(() => {
    const fetchGrades = async () => {
      if (!newTeacher.grade_id) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/class/grade/${newTeacher.grade_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
 
        const data = await response.json();
        if (response.ok) {
          setGrades(data.data);
          console.log("Courses related gradeId: ", data.data);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchGrades();
  }, [newTeacher.grade_id]);
 
  // fetch if teacher have already assigned grade and divisions
  useEffect(() => {
    const alreadyAssignedTeacherGradeAndDivision = async () => {
      if (!newTeacher.grade_id || !newTeacher.teacher) return;
      try {
        const token = getToken();
        if (!token) {
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/teacher/${newTeacher.teacher}/grade/${newTeacher.grade_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setAlreadySelectedTeacherData(data.data);
          console.log("Already saved teacher Data: ", data.data);
          setNewTeacher.grade_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    alreadyAssignedTeacherGradeAndDivision();
  }, [newTeacher.grade_id, newTeacher.teacher]);
 
  // fetch course, grade, division that are already saved then check the selcted items are match one of it. if it matched then give an alert "this division and course already assigned assigned"
  useEffect(() => {
    const alreadyAssignedDivisionAndCourse = async () => {
      if (!newTeacher.grade_id || !newTeacher.grade_division_id || !newTeacher.course_id) return;
      try {
        const token = getToken();
        if (!token) {
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/grade/${newTeacher.grade_id}/division/${newTeacher.grade_division_id}/course/${newTeacher.course_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setAlreadySelectedGradeDivisionCourse(data.data);
          console.log("dataaaaaaaaaaaaaaaa:",response);
         
          console.log("Already saved grade,division and course Data: ", data.data);
          // setError("Already saved grade,division and course Data");
          // setNewTeacher.grade_id = "";
        }
      } catch (error) {
        console.error("Error fetching Already saved grade,division and course Data:", error);
        // setError("Error fetching Already saved grade,division and course Data");
      }
    };
    alreadyAssignedDivisionAndCourse();
  }, [newTeacher.grade_id, newTeacher.grade_division_id, newTeacher.course_id]);
 
  // Fetch courses based on selected grade
  useEffect(() => {
    const token = getToken();
    if(!token){
      console.log("Access denied");
      return;
    }
    const fetchCourses = async () => {
      if (!newTeacher.grade_id) return;
      try {
        if (newTeacher.grade_id === "select_all") {
          // Fetch all courses when "Select All" is selected
         fetchAllCoursesbyschool();
        } else if (newTeacher.grade_id) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course/courses/${newTeacher.grade_id}`,
          {
            headers:{
              'Authorization':`Bearer ${token}`
            }
          }
        );
        setCourses(response.data.data);
        console.log(response.data.data);
      }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [newTeacher.grade_id]);
 
  // Handle course selection
  const handleCourseChange = (courseId) => {
    setNewTeacher((prevState) => {
      const selectedCourses = prevState.courses.includes(courseId)
        ? prevState.courses.filter((id) => id !== courseId) // Remove course if already selected
        : [...prevState.courses, courseId]; // Add course if not selected
      return { ...prevState, courses: selectedCourses };
    });
  };
 
  // Handle class selection
  const handleClassChange = (gradeId, division) => {
    setNewTeacher((prevState) => {
      const currentGrades = [...prevState.grades];
      const index = currentGrades.findIndex((grade) => grade.id === gradeId);
      if (index > -1) {
        // If already selected, remove it
        currentGrades.splice(index, 1);
      } else {
        // Add if not selected
        currentGrades.push({ id: gradeId, division });
      }
      return { ...prevState, grades: currentGrades };
    });
  };
 
  // Handle form submission
  const handleSubmit = async () => {
    const token = getToken();
    if (!token) {
      console.log("Unauthorized User");
    }
    if (
      Object.keys(newTeacher.grade_id).length === 0 || newTeacher.grade_id==="select_all" ||
      Object.values(newTeacher.grade_id).every((div) => div.length === 0)
    ) {
      setError("Please select at least one grade and division.");
      return; // Prevent submission
    }
 
    setError(""); // Clear error if validation passes
    try {
      if (
        !newTeacher.teacherCategory ||
        !newTeacher.teacherSubCategory ||
        !newTeacher.teacher ||
        !newTeacher.grade_id ||
        !newTeacher.grade_division_id ||
        !newTeacher.course_id
      ) {
        // setSnackbarMessage("Fill all the fields");
        // setOpenSnackbar(true);
        alert("Fill all fields");
        return;
      }else if(alreadySelectedGradeDivisionCourse.length>0){
        console.log("new Teacher data: ", newTeacher);
        console.log("AlreadySelectedGradeDivisionCourse: ",alreadySelectedGradeDivisionCourse);
       
       
        setSnackbarMessage("This grade, division and course is already assigned to another teacher");
        setOpenSnackbar(true);
        setAlreadySelectedGradeDivisionCourse([])
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assign_course`,
        newTeacher,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      navigate("/school/assigned-courses-list");
    } catch (err) {
      console.error(err);
      // Optionally handle error
    }
  };

  const handleCancel = () => {
    navigate('/school/assigned-courses-list'); // Navigate to the desired route on cancel
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
 
  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "grey.200",
          padding: "10px",
        }}
      >
        <Typography variant="h6">Assign Courses</Typography>
        {/* <Button variant="contained" color="primary">
          + Create
        </Button> */}
      </Box>
 
      <Grid container spacing={1} sx={{ marginTop: "20px", width: "80%" }}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="#000">
            Select Teacher
          </Typography>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Teacher Category</InputLabel>
            <Select
              value={newTeacher.teacherCategory}
              onChange={(e) =>
                setNewTeacher({
                  ...newTeacher,
                  teacherCategory: e.target.value,
                })
              }
              label="Teacher Category"
              aria-required="true"
            >
              {teacherCategories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.teacher_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Teacher Sub Category</InputLabel>
            <Select
              value={newTeacher.teacherSubCategory}
              onChange={(e) =>
                setNewTeacher({
                  ...newTeacher,
                  teacherSubCategory: e.target.value,
                })
              }
              label="Teacher Sub Category"
              aria-required="true"
              disabled={!newTeacher.teacherCategory}
            >
              {filteredSubcategories.map((subCategory) => (
                <MenuItem key={subCategory._id} value={subCategory._id}>
                  {subCategory.teacher_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel sx={{backgroundColor:"#fff"}}>Select Teacher</InputLabel>
            <Select
              value={newTeacher.teacher}
              // onChange={(e) =>
              //   setNewTeacher({ ...newTeacher, teacher: e.target.value })
               
              // }
              onChange={(e) => {
                setNewTeacher({ ...newTeacher, teacher: e.target.value });
                setAlreadySelectedTeacherData([]); // Uncomment if needed
 
               
              }}
              label="Teacher"
              aria-required="true"
              disabled={!newTeacher.teacherSubCategory}
            >
              {teacherFilter.map((teacher) => (
                <MenuItem key={teacher._id} value={teacher._id}>
                  {teacher.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
         
          {/* <Grid item xs={12} md={6}> */}
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel sx={{backgroundColor:"#fff"}}>Select Grade</InputLabel>
            <Select
              value={newTeacher.grade_id}
              onChange={(e) => {
                setNewTeacher({ ...newTeacher, grade_id: e.target.value });
                setAlreadySelectedTeacherData([]); // Uncomment if needed
              }}
              label="grades"
              aria-required="true"
            >
              <MenuItem value="select_all">Select All</MenuItem> {/* Select All Option */}
              {allGrades.map((grade) => (
                <MenuItem key={grade.grade_id} value={grade.grade_id}>
                  {grade.grade_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          selected grade, division and course:
          <FormControl fullWidth margin="normal" variant="outlined">
            {alreadySelectedTeacherData.length > 0 ? (
              alreadySelectedTeacherData.map((item) => (
                <div key={item._id}>
                  {" "}
                  {/* Ensure you have a unique key for each item */}
                  <span>Grade: {item.grade_name}</span>
                  <span>{item.divisions}</span>
                  <span className="ms-4">Course: {item.course_name}</span>
                </div>
              ))
            ) : (
              <p>No grade and division assigned.</p>
            )}
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel className="mb-4" shrink={true}>
              Select Divisions{" "}
              {/* Manually add the asterisk here if required */}
            </InputLabel>
 
            <FormGroup row className="ms-3 mt-2">
              {/* Filter out divisions that are already selected */}
              {grades
                .filter((grade) => {
                  // Extract selected division names from alreadySelectedTeacherData
                  const selectedDivisionNames = alreadySelectedTeacherData.map(
                    (item) => item.divisions // item.divisions is a string
                  );
 
                  // Show only grades not in selectedDivisionNames
                  return !selectedDivisionNames.includes(grade.division); // Assuming grade.division holds the division string
                })
                .map((grade) => (
                  <FormControlLabel
                    key={grade.division}
                    control={
                      <Checkbox
                        checked={newTeacher.grade_division_id.includes(
                          grade.division
                        )} // Using division string
                        onChange={(e) => {
                          if (e.target.checked) {
                            setNewTeacher((prev) => ({
                              ...prev,
                              grade_division_id: [
                                ...prev.grade_division_id,
                                grade.division, // Push division string
                              ],
                            }));
                          } else {
                            setNewTeacher((prev) => ({
                              ...prev,
                              grade_division_id: prev.grade_division_id.filter(
                                (id) => id !== grade.division // Use division string
                              ),
                            }));
                          }
                        }}
                      />
                    }
                    label={grade.division} // Display the division name
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
 
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel className="mb-4" shrink={true}>
              Select Course {/* Manually add the asterisk here if required */}
            </InputLabel>
 
            <FormGroup row className="ms-3 mt-2 flex-column">
              {/* Filter out divisions that are already selected */}
              {courses
                .map((course) => (
                  <FormControlLabel
                    key={course._id}
                    control={
                      <Radio
                        checked={newTeacher.course_id === course.course_id} // Only one division can be selected
                        onChange={(e) => {
                          if (e.target.checked) {
                            setNewTeacher((prev) => ({
                              ...prev,
                              course_id: course.course_id, // Set the division string
                            }));
                          }
                        }}
                      />
                    }
                    label={course.course_id.courses_name} // Display the division name
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
 
      <Box mt={3}>
        {error && <Typography color="error">{error}</Typography>}{" "}
        {/* Display error message */}
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          ADD
        </Button>
        <Button variant="contained" sx={{mx:2, backgroundColor:'#e0e0e0'}} onClick={handleCancel}>
            CANCEL
          </Button>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
 
export default AssignCourse;
