import React, { useState } from "react";
import Modal from "react-modal";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import {
    Typography,
    Box,
    Button,
    Grid,
} from '@mui/material';

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DisplayUnit() {
    const [isOpen, setIsOpen] = useState(false);
    const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
    const [numPages, setNumPages] = useState(null);
    const [error, setError] = useState(null);

    const contentData = {
        units_content: [
            {
                type: "unitHeading",
                data: "Teacher Sub Cat"
            },
            {
                type: "Heading1",
                data: "<p>asdcaxdcadsv</p>"
            },
            {
                type: "Heading2",
                data: "<p>ascasdv az</p>"
            },
            {
                type: "Subheading1",
                data: "<p>axdvadv a</p>"
            },
            {
                type: "Subheading2",
                data: "<p>savadv vswav</p>"
            },
            {
                type: "paragraph",
                data: "<p>asvda dxvadxcvaw</p>"
            },
            {
                type: "pdf",
                data: [
                    "https://blr1.vultrobjects.com/nanoskool/1726656751148-quill-content%20%282%29.pdf",
                    "https://blr1.vultrobjects.com/nanoskool/1726656751150-quill-content%20%283%29.pdf",
                    "https://blr1.vultrobjects.com/nanoskool/1726656751158-quill-content%20%282%29%20-%20Copy.pdf"
                ]
            },
            {
                type: "gallery",
                data: [
                    "https://blr1.vultrobjects.com/nanoskool/1726656751162-screencapture-github-Inciem-Repo-Nanoskool-Web-actions-runs-10903105223-job-30256448394-2024-09-17-18_10_33.png",
                    "https://blr1.vultrobjects.com/nanoskool/1726656751165-download.jpg",
                    "https://blr1.vultrobjects.com/nanoskool/1726656751165-new-wgs.png.bv.webp",
                    "https://blr1.vultrobjects.com/nanoskool/1726656751165-screencapture-pharmacy-inciem-in-test-login-2024-08-16-11_06_35.png"
                ]
            },
            {
                type: "videoUpload",
                data: [
                    "https://blr1.vultrobjects.com/nanoskool/1726656751174-MicrosoftTeams-video.mp4"
                ]
            },
            {
                type: "videoUrl",
                data: "",
                videoUrl: "https://www.youtube.com/watch?v=TotPvYN_Qa4"
            }
        ],
    };

    const openModal = (index) => {
        setCurrentPdfIndex(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setError(null);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <Box padding={2}>
            {contentData.units_content.map((item, index) => {
                switch (item.type) {
                    case 'unitHeading':
                        return (
                            <Typography variant="h2" key={index}>
                                {item.data}
                            </Typography>
                        );

                    case 'Heading1':
                        return (
                            <Typography variant="h3" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
                        );
                    case 'Heading2':
                        return (
                            <Typography variant="h4" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
                        );

                    case 'Subheading1':
                        return (
                            <Typography variant="h5" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
                        );
                    case 'Subheading2':
                        return (
                            <Typography variant="h6" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
                        );

                    case 'paragraph':
                        return (
                            <Typography variant="body1" key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
                        );

                    case 'pdf':
                        return (
                            <div key={index}>
                                {item.data.map((url, pdfIndex) => (
                                    <div key={pdfIndex} style={{ marginBottom: "20px" }}>
                                        {error && <div style={{ color: 'red' }}>Error: {error.message}</div>}
                                        <Document
                                            file={url} // Load the current PDF
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadError={setError}
                                        >
                                            {Array.from(new Array(numPages), (el, pageIndex) => (
                                                <div key={`page_${pageIndex + 1}`} style={{ marginBottom: "10px" }}>
                                                    <Page pageNumber={pageIndex + 1} />
                                                </div>
                                            ))}
                                        </Document>
                                        <Button onClick={() => setCurrentPdfIndex(null)} style={{ position: "absolute", top: 10, right: 10 }}>
                                            Close
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        );

                    case 'gallery':
                        return (
                            <Grid container spacing={2} key={index}>
                                {item.data.map((url, i) => (
                                    <Grid item xs={12} sm={6} md={4} key={i}>
                                        <img src={url} alt={`Gallery Image ${i + 1}`} style={{ width: '100%', height: 'auto' }} />
                                    </Grid>
                                ))}
                            </Grid>
                        );

                    case 'videoUpload':
                        return (
                            <Box key={index} marginTop={2}>
                                <video controls style={{ width: '100%' }}>
                                    <source src={item.data[0]} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </Box>
                        );

                    case 'videoUrl':
                        return (
                            <Box key={index} marginTop={2}>
                                <Typography variant="body1">Watch this video:</Typography>
                                <Button href={item.videoUrl} target="_blank" variant="contained" color="secondary">
                                    Open YouTube Video
                                </Button>
                            </Box>
                        );

                    default:
                        return null;
                }
            })}
        </Box>
    );
}

export default DisplayUnit;
