import React, { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { getToken } from '../../../utils/AuthUtils';

const EditParentPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [parent, setParent] = useState({
    parents_name: '',
    email: '',
    contact_num: '',
    relation_to_student: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    fetchParent();
  }, [id]);

  const fetchParent = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/parent/${id}`,
        {
          headers:{
            Authorization: `Bearer ${getToken()}`
          }
        }
      );
      const data = await response.json();
      if (data?.data) {
        setParent(data.data);
      } else {
        setError('Parent not found');
      }
    } catch (err) {
      setError('Failed to fetch parent details');
    } finally {
      setLoading(false);
    }
  };

  const saveEditedParent = async (e) => {
    e.preventDefault();
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/parent/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`
         },
        body: JSON.stringify(parent),
      });
      setSnackbarMessage('Parent updated successfully');
      setOpenSnackbar(true);
      navigate('/parents'); // Redirect back to ParentsTable
    } catch (err) {
      setError('Failed to update parent');
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // if (loading) {
  //   return <CircularProgress />;
  // }

  return (
    <div>
      <h2>Edit Parent</h2>
      <form onSubmit={saveEditedParent}>
        <TextField
          label="Name"
          value={parent.parents_name}
          onChange={(e) => setParent({ ...parent, parents_name: e.target.value })}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          type="email"
          value={parent.email}
          onChange={(e) => setParent({ ...parent, email: e.target.value })}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Contact Number"
          type="text"
          value={parent.contact_num}
          onChange={(e) => setParent({ ...parent, contact_num: e.target.value })}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Relation to Student"
          value={parent.relation_to_student}
          onChange={(e) => setParent({ ...parent, relation_to_student: e.target.value })}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate('/parents')}
          style={{ marginLeft: '10px' }}
        >
          Cancel
        </Button>
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditParentPage;
