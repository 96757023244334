import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Switch,
  Grid,
  Link,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";

const TeachersListPage = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [totalItems, setTotalItems] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [teachCategory, setTeachCategory] = useState([]);
  const [selectedTeachCategory, setSelectedTeachCategory] = useState("");
  const [teacherSubCategory, setTeacherSubCategory] = useState([]);
  const [selectedTeachSubCategory, setSelectedTeachSubCategory] = useState("");


  // New state variable for status toggle
  const [statusToggle, setStatusToggle] = useState(false);

  useEffect(() => {
    const message = sessionStorage.getItem("AddTeacherSuccessMessage");
    if (message) {
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      sessionStorage.removeItem("AddTeacherSuccessMessage"); // Remove the message after showing
    }
    fetchTeachers();
  }, [page, rowsPerPage, searchTerm]);

  const fetchTeachers = async () => {
    try {
      const token = getToken();
      if (!token) {
        console.log("Unauthorized user");
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher?page=${
          page + 1
        }&limit=${rowsPerPage}&search=${searchTerm}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data?.data) {
        const teachersWithSerial = data.data.map((teacher, index) => ({
          ...teacher,
          serial: page * rowsPerPage + index + 1,
          statusToggle: !teacher.status, // Add this line
        }));
        setTeachers(teachersWithSerial);
        setSerialNumbers(teachersWithSerial.map((t) => t.serial));
        setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
      } else {
        setError("No teachers found");
      }
    } catch (err) {
      setError("Failed to fetch teachers");
    } finally {
      setLoading(false);
    }
  };

  // department category select list
  useEffect(() => {
    const fetchTeachCategory = async () => {
      // if (!selectedDeptCategory) return;
      try {
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/teachercategory`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setTeachCategory(data.data || []);
          console.log("teachers related department: ", data.data);
          // setNewDepartment.department_id = "";
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchTeachCategory();
  }, []);

    // department sub category select list
    useEffect(() => {
      console.log("teacher category: ", selectedTeachCategory);
      
      const fetchTeachSubCategory = async () => {
        if (!selectedTeachCategory) return;
        try {
          const token = getToken();
          if (!token) {
            console.log("unauthorized user");
            return;
          }
          // select box value
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/teacher/sub/teach/${selectedTeachCategory._id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.ok) {
            setTeacherSubCategory(data.data || []);
            console.log("teachers related department: ", data.data);
            // setNewDepartment.department_id = "";
          }


          const response1 = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/teacher/teachercat/${selectedTeachCategory._id}?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              }
            }
          );
          const data1 = await response1.json();
          console.log(data1.data);
    
          if (data1?.data) {
            const assignProductWithSerial = data1.data.map((product, index) => ({
              ...product,
              serial: page * rowsPerPage + index + 1,
              statusToggle: !product.status, // Add this line
            }));
            setTeachers(assignProductWithSerial);
            setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
            setTotalItems(data1.pagination.totalItems); // Set totalItems for pagination
          } else {
            setError("No assign departments found");
          }
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      };
      fetchTeachSubCategory();
    }, [selectedTeachCategory]);

    useEffect(() => {
      console.log("teacher sub category: ", selectedTeachSubCategory);
      
      const fetchTeachbydeptsubcat = async () => {
        if (!selectedTeachSubCategory) return;
        const token = getToken();
        if (!token) {
          console.log("unauthorized user");
          return;
        }
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/teacher/teachersubcat/${selectedTeachSubCategory._id}?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              }
            }
          );
          const data = await response.json();
          console.log(data.data);
    
          if (data?.data) {
            const assignProductWithSerial = data.data.map((product, index) => ({
              ...product,
              serial: page * rowsPerPage + index + 1,
              statusToggle: !product.status, // Add this line
            }));
            setTeachers(assignProductWithSerial);
            setSerialNumbers(assignProductWithSerial.map((t) => t.serial));
            setTotalItems(data.pagination.totalItems); // Set totalItems for pagination
          } else {
            setError("No assign departments found");
          }
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      };
      fetchTeachbydeptsubcat();
    }, [selectedTeachSubCategory]);

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const deleteTeacher = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher/${deleteId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      });
      if (response.status === 200) {
      setSnackbarMessage("Teacher deleted successfully");
      setOpenSnackbar(true);
      fetchTeachers();
      handleCloseDeleteDialog();
      }
    } catch (err) {
      setError("Failed to delete teacher");
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateTeacherStatus(id, newStatus); // Update parent status in the backend
  };
  const updateTeacherStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/teacher/${id}/status_teacher`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization:`Bearer ${getToken()}`
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Teacher status updated successfully");
      setOpenSnackbar(true);
      fetchTeachers(); // Refresh the table after status change
    } catch (err) {
      setError("Failed to update Teacher status");
    }
  };

  const moveToAddTeacher = () => {
    navigate("/add-teacher");
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#e0e0e0"
        p={1}
        mt={2}>
        <Typography variant="h4">Teachers</Typography>
      </Box>
      {/* <Button variant="contained" color="primary" onClick={() => navigate('/teacher/add-teacher')}>
        Add Teacher
      </Button> */}
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          padding={2}
          gap={1}
          sx={{ paddingRight: "0", paddingLeft: "0" }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: "350px" }}
          />
          <Autocomplete
              options={teachCategory}
              getOptionLabel={(option) =>
                option.teacher_name
                  ? option.teacher_name
                  : "N/A"
              }
              value={
                teachCategory.find((item) => item._id === selectedTeachCategory) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = teachCategory.find(
                    (item) => item._id === newValue._id
                  );
                 
                  setSelectedTeachCategory(
                    selected
                  );
                } else {
                  setSelectedTeachCategory([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select teacher category" />
              )}
              sx={{ width: 350 }}
            />

<Autocomplete
              options={teacherSubCategory}
              getOptionLabel={(option) =>
                option.teacher_name
                  ? option.teacher_name
                  : "N/A"
              }
              value={
                teacherSubCategory.find((item) => item._id === selectedTeachSubCategory) || null
              }
              onChange={(e, newValue) => {
                if (newValue) {
                  const selected = teacherSubCategory.find(
                    (item) => item._id === newValue._id
                  );
                 
                  setSelectedTeachSubCategory(
                    selected
                  );
                } else {
                  setSelectedTeachSubCategory([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select teacher sub category" />
              )}
              sx={{ width: 350 }}
            />
          <Box flexGrow={1} /> {/* This will push the button to the right */}
          <Button
            component={Link}
            // to="/school/assigned-course"
            onClick={moveToAddTeacher}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ backgroundColor: "#e0e0e0" }}>
            Create
          </Button>
        </Box>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Serial No.
              </TableCell>
              <TableCell>
                Teacher ID
              </TableCell>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Teacher category
              </TableCell>
              <TableCell>
              Teacher sub category
              </TableCell>
              <TableCell>
                Mobile
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Hire Date
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.length > 0 ? (
              teachers.map((teacher) => (
                <TableRow key={teacher._id}>
                  <TableCell>{teacher.serial}</TableCell>
                  <TableCell>{teacher.teacher_id}</TableCell>
                  <TableCell>{teacher.name}</TableCell>
                  <TableCell>{teacher.teacher_categoryId?teacher.teacher_categoryId.teacher_name:"N/A"}</TableCell>
                  <TableCell>{teacher.teacher_subCategoryId?teacher.teacher_subCategoryId.teacher_name:"N/A"}</TableCell>
                  <TableCell>{teacher.mobile}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  {/* <TableCell>{teacher.P_email}</TableCell> */}
                  <TableCell>
                    {new Date(teacher.hire_Date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={teacher.status}
                      onChange={() =>
                        handleStatusToggle(teacher._id, teacher.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/edit-teacher/${teacher._id}`)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDeleteDialog(teacher._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">No teachers available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Box>
      )}

       {/* Delete Confirmation Dialog */}
       <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this teacher?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={deleteTeacher}
            color="primary"
            sx={{ backgroundColor: "#e0e0e0" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TeachersListPage;
