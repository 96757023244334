import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import ReactQuill from "react-quill";
import { width } from "@mui/system";
import { getToken } from "../../../utils/AuthUtils";

const Assessment = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
//   const [assessmentAnswers, setAssessmentAnswers]={
//     question1: "",
//     question2: "",
//     question3: ""
//   }

  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/assessment/byId/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${getToken()}`,
                }
              }
            );
            const assessmentData = response.data.data || [];
            setAssessmentQuestions(Array.isArray(assessmentData) ? assessmentData : []);
          } catch (error) {
            console.error("Error fetching assessment questions:", error);
          }
    };
    fetchAssessmentQuestions();
  }, [id]);


  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: value,
    });
  };

//   const handleParagraphChangeHeading1 = (index, value) => {
//     const updatedSections = [...assessmentAnswers];
//     updatedSections[index].data = value;
//     setAssessmentAnswers(updatedSections);
//   };

  const handleSubmit = async() => {
    // const formattedAnswers = assessmentQuestions.map((question,index) => ({
    //     [index]:selectedAnswers[index] || "", // Only the selected answer or an empty string
    //   }));

    const formattedAnswers = assessmentQuestions.map((question, index) => ({
      id: question._id, // Use the question's _id from assessmentQuestions
      answer: selectedAnswers[index] || "", // Selected answer or empty if unanswered
    }));

    const payload = {
      answers: formattedAnswers,
      assessment_id: id, // Using the id from useParams as assessment_id
    };

    console.log("Payload:", payload);

      let totalPoints = 0;

    // Calculate total points
    // assessmentQuestions.forEach((question, index) => {
    //     if (selectedAnswers[index] === question.answer) {
    //         totalPoints += question.points; // Add points if the answer is correct
    //     }
    // });

    assessmentQuestions.forEach((question, index) => {
      const selectedAnswer = selectedAnswers[index];

      // Calculate points only for "yes/no" and "mcq" types
      if (question.question_type === "mcq" || question.question_type === "yes/no") {
          if (selectedAnswer === question.answer) {
              totalPoints += question.points; // Add points if the answer is correct
          }
      }
      // For "descriptive" questions, points are always 0, so no action needed.
  });


    
      // console.log("Formatted Answers:", formattedAnswers);
      // console.log("Total Points:", totalPoints); 
    console.log("Selected Answers:", selectedAnswers);
    try {
        const token = getToken();
        if(!token){
            console.log("Unauthorized Access");
            return;
        }
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/assessment`,{
            ...payload,
            // total_points: totalPoints, // Adding total_points to the payload if needed
          },
          {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                },
                }
        );
        const assesmentData = response.data.data;
        setAssessmentQuestions(assesmentData);
        navigate('/student/unit-assessment/submitted')
        console.log("assesmentData: ",assesmentData);

        // const filteredTeachers = resourcePersonData.filter(
        //   (item) => item.teacher_subCategoryId === newProduct.teacherSubCategory
        // );
        // setTeacherFilter(filteredTeachers);
      } catch (error) {
        console.error("Error fetching teachers:", error);
      }

    
  };

  return (
    <Container maxWidth="lg">
      {/* Breadcrumb Section */}
      <Box sx={{ backgroundColor: "#9e503d", height: "50px" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              height: "50px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: "18px", color: "#fff !important" }}
            >
              <ArrowRightIcon sx={{color: "#fff !important"}}/> HOME COURSE 1 GRADE 1 VOL 1<ArrowRightIcon sx={{color: "#fff !important"}}/> MUSICAL PROGRAMMING: COMPOSING WITH
              MODI MODULES <ArrowRightIcon sx={{color: "#fff !important"}}/> PERIOD 1
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f1f1",
          height: "100px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          marginBottom: "30px",
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Typography variant="h3" gutterBottom>
            MCQ Type Questions
          </Typography>
        </Container>
      </Box>
      {/* MCQ Section */}
      {Array.isArray(assessmentQuestions) && assessmentQuestions.length > 0 ? (
  assessmentQuestions.map((q, index) => (
    <Box key={index} mb={2}>
      <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
        {`${index + 1}. ${q.question_text}`}
      </Typography>
      {q.question_type === "mcq" && (
        <RadioGroup
          name={`question-${index}`}
          value={selectedAnswers[index] || ""}
          onChange={(e) => handleAnswerChange(index, e.target.value)}
        >
          {q.options.map((option, i) => (
            <FormControlLabel
              key={i}
              value={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      )}

      {q.question_type === "yes/no" && (
        <RadioGroup
          name={`question-${index}`}
          value={selectedAnswers[index] || ""}
          onChange={(e) => handleAnswerChange(index, e.target.value)}
        >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
      )}

      {q.question_type === "descriptive" && (
        <textarea
          style={{ width: "100%", border: "1px solid" }}
          value={selectedAnswers[index] || ""}
          onChange={(e) => handleAnswerChange(index, e.target.value)}
        />
      )}
    </Box>
  ))
) : (
  <Typography>No questions available.</Typography>
)}
      

      {/* Submit Button */}
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit Answers
        </Button>
      </Box>
    </Container>
  );
};

export default Assessment;
