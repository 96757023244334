import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Box,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";
import { useNavigate } from "react-router-dom";

const ResetPassword = ({ onSuccess }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setErrorMessage(""); // Clear error message on each attempt
    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }

    try {
      const token = getToken();
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/password-reset`,
        {
          oldPassword,
          password: newPassword,
          confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onSuccess(); // Callback to notify parent component
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message || "Failed to reset password."
        );
      } else {
        setErrorMessage("Internal server error.");
      }
    }
  };

  const handleCancel = () => {
    navigate(-1); // Navigate to the desired route on cancel
  };

  return (
    <Container component="main" maxWidth="xl"
    sx={{
      padding: "20px",
      borderRadius: "8px",
    }}>
      <Paper elevation={3} style={{ padding: "16px", marginTop: "32px" }}>
        <Typography variant="h5" align="center">
          Reset Password
        </Typography>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        <Box component="form" noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            label="Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            type="password"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            onClick={handleResetPassword}
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: "16px" }}
          >
            Reset Password
          </Button>
          <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={handleCancel} // You can define this function to handle the cancel action
              sx={{ mt: 2, backgroundColor: "#e0e0e0" }}
            >
              Cancel
            </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
