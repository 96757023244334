import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill styles
import axios from "axios";
import { textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { getToken, getDecodeToken } from "../../../utils/AuthUtils";
import { useParams } from "react-router-dom";


const saveContent = async (contentSections) => {
  const formData = new FormData();

  formData.append(`unit`, localStorage.getItem("unit"));
  formData.append(`user_id`, localStorage.getItem("user_id"));
  formData.append(`projectImage`, localStorage.getItem("projectImage"));
  // formData.append(`course`, localStorage.getItem("course"));
  // Append the heading to formData
  if (contentSections[0]?.unitHeading) {
    formData.append(`projectHeading`, contentSections[0].unitHeading);
  }

  contentSections.forEach((section, index) => {
    formData.append(`contentSections[${index}][type]`, section.type);
    formData.append(`contentSections[${index}][data]`, section.data || "");

    if (section.galleryFiles) {
      for (let i = 0; i < section.galleryFiles.length; i++) {
        formData.append(
          `contentSections[${index}][galleryFiles][]`,
          section.galleryFiles[i]
        );
      }
    }

    if (section.pdfFiles) {
      for (let i = 0; i < section.pdfFiles.length; i++) {
        formData.append(
          `contentSections[${index}][pdfFiles][]`,
          section.pdfFiles[i]
        );
      }
    }

    if (section.videoFile) {
      formData.append(
        `contentSections[${index}][videoFile]`,
        section.videoFile
      );
    }

    if (section.videoUrl) {
      formData.append(`contentSections[${index}][videoUrl]`, section.videoUrl);
    }

    if (section.youtubeUrl) {
      formData.append(
        `contentSections[${index}][youtubeUrl]`,
        section.youtubeUrl
      );
    }

    if (section.vimeoUrl) {
      formData.append(`contentSections[${index}][vimeoUrl]`, section.vimeoUrl);
    }

    if (section.imageFile) {
      formData.append(
        `contentSections[${index}][imageFile]`,
        section.imageFile
      );
    }
  });
  for (let [key, value] of formData.entries()) {
    console.log(`${key}:`, value);
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/projectupload/save`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token_key')
          }
      }
    );
    console.log("Success:", formData);
    console.log("Success response:", response);
  } catch (error) {
    console.error("Error:", error);
  }
};

const DynamicContentForm = () => {
  const { id } = useParams();
  const [contentSections, setContentSections] = useState([]);
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [CourseData, setCourseData] = useState([]);
  const [GradeData, setGradeData] = useState([]);
  const [ChapterData, setChapterData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const setUnitId = async () => {
      localStorage.setItem("unit", id);
      const token = getDecodeToken()
      localStorage.setItem("user_id", token.reg_id);
    };

    setUnitId();
  }, []);
  // Fetch grades
  // useEffect(() => {
  //   const fetchGrades = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND}/api/grade`
  //       );
  //       setGrades(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching grades:", error);
  //     }
  //   };

  //   fetchGrades();
  // }, []);

  // useEffect(() => {
  //   const fetchCourses = async () => {
  //     try {
  //       const token = getToken();
  //       if (token && GradeData) {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_BACKEND}/api/courses/bygrade/${GradeData}`,
  //           {
  //             headers: { Authorization: `Bearer ${token}` },
  //             // params: { gradeId: GradeData },
  //           }
  //         );
  //         setCourseList(response.data.data);
  //       } else {
  //         setCourseList([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching courses:", error);
  //     }
  //   };

  //   fetchCourses();
  // }, [GradeData]);

  // useEffect(() => {
  //   const fetchChapters = async () => {
  //     try {
  //       if (CourseData) {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_BACKEND}/api/chapters/bycourse/${CourseData}`,
  //           {
  //             // params: { courseId: CourseData },
  //           }
  //         );
  //         setChapters(response.data.data || []);
  //       } else {
  //         setChapters([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching chapters:", error);
  //     }
  //   };

  //   fetchChapters();
  // }, [CourseData]);

  const handleSave = async () => {
    try {
      await saveContent(contentSections); // Wait for content to save
      navigate("/student/project-page"); // Navigate after successful save
    } catch (error) {
      console.error("Failed to save content", error);
    }
  };
  const handleContentTypeChange = (event) => {
    const { value } = event.target;
    setContentSections((prevSections) => [
      ...prevSections,
      { type: value, id: new Date().getTime(), data: "" },
    ]);
  };
  const handleRemoveSection = (index) => {
    setContentSections((prevSections) =>
      prevSections.filter((_, i) => i !== index)
    );
  };

  const handleProjectImageChange = async (event) => {
    const file = event.target.files[0]; // Only handle one file

    if (!file) return;

    const formData = new FormData();
    formData.append("imageFile", file); // Append the selected file

    try {
      // setGalleryLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/uploadfile/savefile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );


      localStorage.setItem("projectImage", response.data.fileUrl);
    } catch (err) {
      console.error(err.message || "Failed to upload file");
    } finally {
      // setGalleryLoading(false);
    }
  };
  const handleParagraphChange = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeHeading1 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeHeading2 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeSubHeading1 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeSubHeading2 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };

  const handleGalleryChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].galleryFiles = event.target.files;
    setContentSections(updatedSections);
  };

  const handlePdfUploadChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].pdfFiles = event.target.files;
    setContentSections(updatedSections);
  };

  const handleVideoUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedSections = [...contentSections];
    updatedSections[index].videoUrl = value;
    setContentSections(updatedSections);
  };

  const handleYoutubeUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedSections = [...contentSections];
    updatedSections[index].youtubeUrl = value;
    setContentSections(updatedSections);
  };

  const handleVimeoUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedSections = [...contentSections];
    updatedSections[index].vimeoUrl = value;
    setContentSections(updatedSections);
  };

  const handleVideoUploadChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].videoFile = event.target.files[0];
    setContentSections(updatedSections);
  };

  const handleSingleImageChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].imageFile = event.target.files[0];
    setContentSections(updatedSections);
  };

  const handleHeadingChange = (event) => {
    const updatedSections = [...contentSections];

    // Make sure there is a section for the heading, if not, create it
    if (!updatedSections[0]) {
      updatedSections[0] = { projectHeading: "", type: "projectHeading" };
    }

    updatedSections[0].data = event.target.value;
    setContentSections(updatedSections);
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        padding: 3,
        marginLeft: "0px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Project Uploads
      </Typography>
      <Divider sx={{ marginBottom: 3 }} />
      <TextField
        type="text"
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder="Project Heading"
        label="Project Heading"
        sx={{ marginBottom: 3 }}
        onChange={handleHeadingChange}
        required
      />
      <TextField
        type="file"
        variant="outlined"
        margin="normal"
        inputProps={{ accept: "image/*" }}
        onChange={(e) => handleProjectImageChange(e)}
        sx={{ marginTop: 1, width: "100%" }}
        required
      />

      {/* Dynamically render fields based on selected content types */}
      {contentSections.map((section, index) => {
        index = index++;
        switch (section.type) {
          case "paragraph":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label>Paragraph</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) => handleParagraphChange(index, value)}
                />
              </Box>
            );
          case "gallery":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label>Gallery</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ multiple: true, accept: "image/*" }}
                  onChange={(e) => handleGalleryChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "Heading1":
            return (
              <Box key={section.id} sx={{ marginBottom: 3 }}>
                <label htmlFor="">Heading 1</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeHeading1(index, value)
                  }
                />
              </Box>
            );
          case "Heading2":
            return (
              <Box key={section.id} sx={{ marginBottom: 3 }}>
                <label htmlFor="">Heading 2</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeHeading2(index, value)
                  }
                />
              </Box>
            );
          case "Subheading1":
            return (
              <Box key={section.id} sx={{ marginBottom: 3 }}>
                <label htmlFor="">Sub Heading 1</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeSubHeading1(index, value)
                  }
                />
              </Box>
            );
          case "Subheading2":
            return (
              <Box key={section.id} sx={{ marginBottom: 3 }}>
                <label htmlFor="">Sub Heading 2</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeSubHeading2(index, value)
                  }
                />
              </Box>
            );
          case "videoUpload":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Video</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "video/*" }}
                  onChange={(e) => handleVideoUploadChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "pdf":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">PDF</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  name="pdfFiles"
                  type="file"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "application/pdf", multiple: true }}
                  onChange={(e) => handlePdfUploadChange(index, e)}
                  label={`Upload PDFs for section ${index + 1}`}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "videoUrl":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Video URL</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  label="Video URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleVideoUrlChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "youtubeUrl":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Youtube URL</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  label="Video URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleYoutubeUrlChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "vimeoUrl":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Vimeo URL</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  label="Video URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleVimeoUrlChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "singleImage":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Single Image </label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "image/*" }}
                  onChange={(e) => handleSingleImageChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          default:
            return null;
        }
      })}

      {/* Main Select to choose content type */}
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>Choose content</InputLabel>
        <Select
          label="Choose content"
          onChange={handleContentTypeChange}
          sx={{ marginBottom: 3 }}
        >
          <MenuItem value="Heading1">Heading 1</MenuItem>
          <MenuItem value="Heading2">Heading 2</MenuItem>
          <MenuItem value="Subheading1">Sub Heading 1</MenuItem>
          <MenuItem value="Subheading2">Sub Heading 2</MenuItem>
          <MenuItem value="paragraph">Paragraph</MenuItem>
          <MenuItem value="pdf">PDF Upload</MenuItem>
          <MenuItem value="gallery">Photos</MenuItem>
          <MenuItem value="videoUpload">Video Upload</MenuItem>
          <MenuItem value="videoUrl">Video URL</MenuItem>
          <MenuItem value="youtubeUrl">Youtube URL</MenuItem>
          <MenuItem value="vimeoUrl">Vimeo URL</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ marginTop: 3, width: "20%", color: "#e0e0e0" }}
      >
        Save
      </Button>
    </Box>
  );
};

export default DynamicContentForm;
