import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Rating,
  Grid,
  Pagination,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility"; // Eye icon
import bann from "../../../assets/1234.png"; // You can change this image
import NotFound from "../../../assets/NOT_FOUND.png"; // Add the "No Projects Found" image
import axios from "axios";
import { Edit } from "@mui/icons-material";

const ProjectPage = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const fetchUnit = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/listproject`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setProjects(response.data.data);
    } catch (err) {
      setError(err.message || "Failed to fetch project data");
    }
  };

  useEffect(() => {
    fetchUnit();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleEdit = async (id) => {
    navigate(`/student/editproject/${id}`);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      {projects.length > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <TextField
            label="Search Projects"
            variant="outlined"
            sx={{ width: "300px" }}
          />
        </Box>
      )}

      {/* Check if there are no projects */}
      {projects.length > 0 ? (
        // List of Project Cards
        <Grid container spacing={2}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "15px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  {/* Image Section */}
                  <Box
                    component="img"
                    src={project.project_image} // Replace with actual image source if needed
                    alt={project.project_title}
                    sx={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "12px",
                      mb: 2,
                    }}
                  />

                  {/* Product Name and Info */}
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {project.project_title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 1 }}
                  >
                    Uploaded by: {project.student_id.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mb: 1 }}
                  >
                    {project.student_id.grade_division}
                  </Typography>
                </CardContent>

                {/* View Project Button (Eye Icon) */}
                <IconButton
                  sx={{
                    position: "absolute",
                    bottom: 10,
                    right: 10,
                    color: "#1976d2",
                  }}
                  onClick={() => navigate(`/projectinterface/${project._id}`)} // Navigate to the ProjectView page
                >
                  <VisibilityIcon /> {/* Eye icon */}
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => handleEdit(project._id)}
                >
                  <Edit />
                </IconButton>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        // Display message and image when there are no projects
        <Box mt={10} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "50px",
              opacity: 0.3,
            }}
          >
            No projects found
          </Typography>
          <Box
            sx={{ opacity: "0.1", display: "flex", justifyContent: "center" }}
          >
            <img src={NotFound} alt="No Projects Found" />
          </Box>
        </Box>
      )}

      {/* Pagination at the Bottom Left */}
      {/* <Box mt={4} display="flex" justifyContent="flex-start">
        <Pagination
          count={10}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box> */}
    </Box>
  );
};

export default ProjectPage;
