import Axios from 'axios';
import { jwtDecode } from "jwt-decode"; // Corrected import

const tokenKey = 'token_key';

export function getToken() {
  // const tokenCookie = document.cookie.split('; ').find(row => row.startsWith(`${tokenKey}=`));
  // return tokenCookie ? tokenCookie.split('=')[1] : null;
  return localStorage.getItem(tokenKey);
  
}

export function getDecodeToken() {
 
  const value = localStorage.getItem(tokenKey);
  return jwtDecode(value);
}
// console.log(getToken());


export function saveToken(token) {
  document.cookie = `${tokenKey}=${token}; Path=/; HttpOnly; Secure`;
}

export function removeToken() {
  localStorage.removeItem(tokenKey);
  document.cookie = `${tokenKey}=; Path=/; Expires=Thu, 01 Jan 1970 00:00 GMT`;
}

export async function verifyToken() { // Marked as async
  Axios.defaults.withCredentials = true;
  const token = getToken();
  
  if (!token) {
    return false; // No token found
  }

  try {
    // Make an authenticated request to verify the token
    const response = await Axios.get(`${process.env.REACT_APP_BACKEND}/auth/verify`, {
      headers: {
        'Authorization': `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    
    // Return the status from the backend response
    return response.data.status;
  } catch (error) {
    console.error('Error verifying token:', error);
    return false; // Return false if there was an error
  }
}

export function getRole() {
  const token = getToken();
  if (!token) {
    return null;
  }
  const decodedToken = jwtDecode(token);
  console.log("decode token"+decodedToken);
  
  return decodedToken.role_name;
}
