import React, { useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";

const AddResourcePerson = () => {
  const navigate = useNavigate();
  const [newTeacher, setNewTeacher] = useState({
    short_name: "",
    teacher_id: "",
    name: "",
    qualification: "",
    mobile: "",
    email: "",
    hire_Date: "",
    subject_specialization: "",
  });

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);

  const addTeacher = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/teacher`, {
        method: "POST",
        headers: { "Content-Type": "application/json",
          "Authorization": `Bearer ${getToken()}`
         },
        body: JSON.stringify(newTeacher),
      });
      setSnackbarMessage("Teacher added successfully");
      setOpenSnackbar(true);
      navigate("/");
    } catch (err) {
      setError("Failed to add teacher");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Add New Teacher
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={addTeacher}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Teacher Short Name"
                value={newTeacher.short_name}
                onChange={(e) =>
                  setNewTeacher({ ...newTeacher, short_name: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={newTeacher.name}
                onChange={(e) =>
                  setNewTeacher({ ...newTeacher, name: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Qualification"
                value={newTeacher.qualification}
                onChange={(e) =>
                  setNewTeacher({
                    ...newTeacher,
                    qualification: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Mobile"
                type="number"
                value={newTeacher.mobile}
                onChange={(e) =>
                  setNewTeacher({ ...newTeacher, mobile: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                value={newTeacher.email}
                onChange={(e) =>
                  setNewTeacher({ ...newTeacher, email: e.target.value })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Hire Date"
                type="date"
                value={newTeacher.hire_Date}
                onChange={(e) =>
                  setNewTeacher({
                    ...newTeacher,
                    hire_Date: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Subject Specialization"
                value={newTeacher.subject_specialization}
                onChange={(e) =>
                  setNewTeacher({
                    ...newTeacher,
                    subject_specialization: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                {loading ? <CircularProgress size={24} /> : "Add Teacher"}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddResourcePerson;
